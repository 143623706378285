import { useToast } from "~/ui/use-toast"
import { invitePath } from "~/common/paths"

export const useCopyShareLink = () => {
  const { toast } = useToast()

  return async (shareToken: string, inviterId: string) => {
    const link = `${window.location.origin}${invitePath({ inviteToken: shareToken })}/?inviter=${inviterId}`

    try {
      await navigator.clipboard.writeText(link)
      toast({
        title: "Share link copied",
        description: "The group's share link has been copied to your clipboard",
      })
    } catch {
      // in dev, navigator.clipboard may not be available (insecure context thing)
      window.prompt("Copy to clipboard: Ctrl+C, Enter", link)
    }
  }
}
