import { useQuery } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import React from "react"
import { useForm } from "react-hook-form"
import { Link, useNavigate, useParams } from "react-router-dom"
import { z } from "zod"
import { gql } from "~/__generated__"
import { gqlMatchOptional } from "~/common/gql-match"
import { bookmarkEditPath, bookmarksPath, groupDetailPath } from "~/common/paths"
import { useSafeMutation } from "~/common/use-safe-mutation"
import airplane from "~/images/airplane"
import linkIcon from "~/images/link-icon.svg"
import pencilIcon from "~/images/pencil-icon"
import trash8 from "~/images/trash-8"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "~/ui/alert-dialog"
import { AutosizeTextarea } from "~/ui/autosize-textarea"
import { Button } from "~/ui/button"
import { CenteredLoadingSpinner } from "~/ui/delayed-loading-spinner"
import { Form, FormControl, FormField, FormItem, FormMessage } from "~/ui/form"
import { LoadingSpinner } from "~/ui/loading-spinner"
import Pill from "~/ui/pill"
import { RelativeDatetime } from "~/ui/relative-date"
import { toast, useToast } from "~/ui/use-toast"
import { UserAvatar } from "~/users/user-avatar"
import { BookmarkEnrichmentScreenshotCardImage } from "./bookmark-enrichment-screenshot-card-image"

type BookmarkDetailParams = {
  bookmarkId: string
}

const BOOKMARK_DELETE_MUTATION = gql(/* GraphQL */ `
  mutation BookmarkDeleteMutation($input: String!) {
    bookmarkDelete(input: { bookmarkId: $input }) {
      success
    }
  }
`)

const BOOKMARK_QUERY = gql(/* GraphQL */ `
  query BookmarkDetailScreen($bookmarkId: ID, $commentsAfter: String) {
    bookmark: node(id: $bookmarkId) {
      __typename
      id
      ... on Bookmark {
        id
        createdAt
        title
        url
        user {
          id
          firstName
          fullName
          lastName
        }
        enrichment {
          id
          description
          ...BookmarkEnrichmentScreenshotCardImage
          tags {
            nodes {
              id
              name
            }
          }
        }
        groups {
          id
          name
        }
        comments(first: 50, after: $commentsAfter) {
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              id
              body
              createdAt
              user {
                id
                fullName
                ...UserAvatar
              }
              canDelete {
                value
              }
            }
          }
        }
      }
    }
  }
`)

const COMMENT_CREATE_MUTATION = gql(/* GraphQL */ `
  mutation CommentCreate($input: CommentCreateInput!) {
    commentCreate(input: $input) {
      comment {
        id
        body
        createdAt
      }
    }
  }
`)

const commentFormSchema = z.object({
  body: z.string().min(1, "Comment is required"),
})

type CommentFormValues = z.infer<typeof commentFormSchema>

const CommentForm = ({ bookmarkId }: { bookmarkId: string }) => {
  const { toast } = useToast()
  const formRef = React.useRef<HTMLFormElement>(null)

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && (e.metaKey || e.ctrlKey)) {
      e.preventDefault()
      formRef.current?.requestSubmit()
    }
  }

  const form = useForm<CommentFormValues>({
    resolver: zodResolver(commentFormSchema),
    defaultValues: {
      body: "",
    },
  })

  const [createComment, { loading }] = useSafeMutation(COMMENT_CREATE_MUTATION, {
    refetchQueries: ["BookmarkDetailScreen"],
    onError: (error) => {
      toast({
        title: "Error",
        description: error.message,
        variant: "destructive",
      })
    },
  })

  const onSubmit = async (values: CommentFormValues) => {
    const { errors } = await createComment({
      variables: {
        input: {
          commentInput: {
            body: values.body,
            bookmarkId,
          },
        },
      },
    })

    if (errors) {
      toast({
        title: "Error",
        description: "Failed to create comment",
        variant: "destructive",
      })
      return
    }

    form.reset()
    toast({
      title: "Success",
      description: "Comment added successfully",
    })
  }

  return (
    <Form {...form}>
      <form
        ref={formRef}
        onSubmit={form.handleSubmit(onSubmit)}
        className="mt-4 flex items-center gap-2"
      >
        <FormField
          name="body"
          render={({ field }) => (
            <FormItem className="flex-1">
              <FormControl>
                <AutosizeTextarea
                  {...field}
                  placeholder="Add a comment..."
                  onKeyDown={handleKeyDown}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button type="submit" disabled={loading} variant={"outline"}>
          {loading ? <LoadingSpinner className="h-4 w-4" /> : <img {...airplane} alt="" />}
        </Button>
      </form>
    </Form>
  )
}

const COMMENT_DELETE_MUTATION = gql(/* GraphQL */ `
  mutation CommentDelete($input: CommentDeleteInput!) {
    commentDelete(input: $input) {
      comment {
        id
      }
    }
  }
`)

export const BookmarkDetailScreen = () => {
  const [deleteCommentDialog, setDeleteCommentDialog] = React.useState<{
    id: string
    body: string
  } | null>(null)
  const { bookmarkId } = useParams<BookmarkDetailParams>()
  const {
    data: bookmarkData,
    loading: isBookmarkLoading,
    error: bookmarkError,
    previousData,
    fetchMore,
  } = useQuery(BOOKMARK_QUERY, {
    variables: { bookmarkId: bookmarkId || "" },
  })
  const bookmark = gqlMatchOptional(bookmarkData?.bookmark ?? previousData?.bookmark, "Bookmark")
  const navigate = useNavigate()

  const [deleteBookmark] = useSafeMutation(BOOKMARK_DELETE_MUTATION, {
    onError: (error) => {
      toast({
        title: "Error",
        description: error.message,
        variant: "destructive",
      })
    },
  })

  const [deleteComment] = useSafeMutation(COMMENT_DELETE_MUTATION)

  const handleDeleteBookmark = async () => {
    const confirmResponse = confirm("Are you sure you want to delete this bookmark?")
    if (!confirmResponse) return
    const bookmarkId = bookmark?.id
    if (!bookmarkId) {
      toast({ title: "Bookmark failed to load" })
      return
    }
    try {
      const deleteResult = await deleteBookmark({ variables: { input: bookmarkId } })
      if (deleteResult.data?.bookmarkDelete?.success) {
        toast({
          title: "Bookmark Deleted",
        })
        navigate(bookmarksPath({}))
      } else {
        toast({
          title: "Failed to delete bookmark",
          description: JSON.stringify(deleteResult.errors),
        })
      }
    } catch (e) {
      console.error("Failed to delete bookmark:", e)
      toast({
        title: "Failed to delete bookmark",
      })
    }
  }

  return isBookmarkLoading && !bookmark ? (
    <CenteredLoadingSpinner />
  ) : bookmarkError ? (
    <div>Error: {JSON.stringify(bookmarkError)}</div>
  ) : !bookmark ? (
    <div>Failed to load Bookmark</div>
  ) : (
    <>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 mb-8">
          <div className="flex justify-between text-xs">
            <Link to={bookmarksPath({})}>
              {"<"} <span className="text-gray-500">All Bookmarks</span>
            </Link>
            <div>
              {bookmark && (
                <Link className="text-gray-500" to={bookmarkEditPath({ bookmarkId: bookmark.id })}>
                  <img {...pencilIcon} className="mr-2 inline-flex" />
                  Edit Details
                </Link>
              )}
              <button className="ml-4 text-gray-500" onClick={handleDeleteBookmark}>
                Remove
              </button>
            </div>
          </div>
        </div>
        <div className="col-span-12">
          <div className="mb-2 flex text-xs">
            <div className="flex flex-1 space-x-2">
              <div className="">
                <UserAvatar user={bookmark.user} className="h-8 w-8" />
              </div>
              <div className="flex flex-col justify-center align-middle">
                <div>
                  {bookmark.groups && bookmark.groups.length > 0 && (
                    <span className="mr-1">
                      <span className="mr-1 text-gray-400">In</span>
                      <span>
                        {bookmark.groups.map((group, i) => [
                          i > 0 && ", ",
                          <Link to={groupDetailPath({ id: group.id })} key={group.id}>
                            {group.name}
                          </Link>,
                        ])}
                      </span>
                    </span>
                  )}
                  <span className="text-gray-400">by</span> {bookmark.user?.fullName}
                </div>
              </div>
            </div>
            <div className="relative flex-1 text-right text-gray-400">
              <RelativeDatetime input={bookmark.createdAt} />
            </div>
          </div>
          <div className="flex">
            <div className="flex-1">
              <h2 className="flex space-x-2 text-lg font-medium">
                <a
                  href={bookmark.url}
                  className="hover:text-blue-500"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {bookmark.title}
                </a>
                <a
                  href={bookmark.url}
                  className="self-center"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={linkIcon} className="rounded-full bg-gray-200" width={18} height={18} />
                </a>
              </h2>
              <p className="text-sm text-gray-400">{bookmark.enrichment.description}</p>
            </div>

            <BookmarkEnrichmentScreenshotCardImage enrichment={bookmark.enrichment} />
          </div>
        </div>
        <div className="col-span-12">
          <div className="mt-6 flex justify-start space-x-2">
            {bookmark?.enrichment?.tags &&
              bookmark.enrichment.tags.nodes.length > 0 &&
              bookmark.enrichment.tags.nodes.map((tag) => (
                <Pill key={tag.id} name={tag.name} className="border bg-white text-gray-800" />
              ))}
          </div>
        </div>
        <div className="col-span-12">
          <CommentForm bookmarkId={bookmark.id} />
          <div className="my-4">
            <h3 className="mb-4 text-lg font-medium">Comments</h3>
            {bookmark.comments.edges.length === 0 ? (
              <p className="text-sm text-gray-500">No comments yet</p>
            ) : (
              <div className="space-y-4">
                {bookmark.comments.edges.map(({ node }) => (
                  <div key={node.id} className="flex gap-4">
                    <UserAvatar user={node.user} size="sm" />
                    <div className="flex-1">
                      <div className="mb-2 mt-1 flex items-center justify-between">
                        <div className="flex h-4 items-center">
                          <span className="text-xs">{node.user.fullName}</span>

                          {node.canDelete.value && (
                            <Button
                              variant="secondary"
                              size="icon"
                              className="ms-4 h-4 w-4 rounded-full p-1"
                              onClick={() =>
                                setDeleteCommentDialog({ id: node.id, body: node.body })
                              }
                            >
                              <img {...trash8} />
                            </Button>
                          )}
                        </div>
                        <div className="flex items-center gap-2 text-xs text-gray-600">
                          <RelativeDatetime input={node.createdAt} />
                        </div>
                      </div>
                      <p className="mt-1 whitespace-pre text-sm text-gray-600">{node.body}</p>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {bookmark.comments.pageInfo.hasNextPage && (
              <div className="mt-4 flex justify-center">
                <Button
                  variant="outline"
                  onClick={() => {
                    fetchMore({
                      variables: {
                        bookmarkId: bookmark.id,
                        commentsAfter: bookmark.comments.pageInfo.endCursor,
                      },
                    })
                  }}
                >
                  Load more comments
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>

      <AlertDialog open={!!deleteCommentDialog} onOpenChange={() => setDeleteCommentDialog(null)}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Delete comment</AlertDialogTitle>
            <AlertDialogDescription asChild>
              <div>Are you sure you want to delete this comment? This action cannot be undone.</div>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={async () => {
                if (!deleteCommentDialog) return

                const { errors } = await deleteComment({
                  variables: {
                    input: {
                      id: deleteCommentDialog.id,
                    },
                  },
                  refetchQueries: ["BookmarkDetailScreen"],
                })

                if (!errors) {
                  toast({
                    title: "Success",
                    description: "Comment deleted successfully",
                  })
                  setDeleteCommentDialog(null)
                }
              }}
            >
              Delete Comment
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}
