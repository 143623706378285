import { z } from "zod"

const groupInvitationSchema = z.object({
  shareToken: z.string(),
  inviterId: z.string().optional(),
})

type GroupInvitation = z.infer<typeof groupInvitationSchema>

const STORAGE_KEY = "groupInvitation"

export const useGroupInvitationStorage = () => {
  const setGroupInvitation = (invitation: GroupInvitation) => {
    try {
      const validated = groupInvitationSchema.parse(invitation)
      localStorage.setItem(STORAGE_KEY, JSON.stringify(validated))
    } catch (e) {
      console.error("Invalid group invitation data:", e)
      clearGroupInvitation()
    }
  }

  const getGroupInvitation = (): GroupInvitation | null => {
    try {
      const stored = localStorage.getItem(STORAGE_KEY)
      if (!stored) return null

      const parsed = JSON.parse(stored)
      return groupInvitationSchema.parse(parsed)
    } catch (e) {
      console.error("Failed to parse stored group invitation:", e)
      clearGroupInvitation()
      return null
    }
  }

  const clearGroupInvitation = () => {
    localStorage.removeItem(STORAGE_KEY)
  }

  return {
    setGroupInvitation,
    getGroupInvitation,
    clearGroupInvitation,
  }
}
