import { useQuery } from "@apollo/client"
import { Link, useNavigate, useParams } from "react-router-dom"
import { gql } from "~/__generated__"
import { gqlMatchOptional } from "~/common/gql-match"
import { bookmarkDetailPath } from "~/common/paths"
import { useFormErrors } from "~/common/use-form-errors"
import * as z from "zod"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { useSafeMutation } from "~/common/use-safe-mutation"
import { Button } from "~/ui/button"
import { Form } from "~/ui/form"
import { FloatingTextField } from "~/fields/floating-text-field"
import { useEffect, useState } from "react"
import Pill from "~/ui/pill"
import { PillField } from "~/fields/pill-field"
import GroupsCheckboxes from "~/fields/groups-checkboxes"
import { useToast } from "~/ui/use-toast"

type BookmarkEditParams = {
  bookmarkId: string
}

const BOOKMARK_QUERY = gql(/* GraphQL */ `
  query BookmarkEditScreen($bookmarkId: ID) {
    bookmark: node(id: $bookmarkId) {
      __typename
      id
      ... on Bookmark {
        id
        createdAt
        title
        notes
        url
        user {
          id
          firstName
          fullName
          lastName
        }
        enrichment {
          id
          description
          tags {
            nodes {
              id
              name
            }
          }
        }
        groups {
          id
          name
        }
      }
    }
  }
`)

const BOOKMARK_UPDATE_MUTATION = gql(/* GraphQL */ `
  mutation BookmarkUpdate($input: BookmarkUpdateInput!) {
    bookmarkUpdate(input: $input) {
      bookmark {
        id
        title
        notes
        enrichment {
          description
          tags {
            nodes {
              id
              name
            }
          }
        }
      }
    }
  }
`)

const bookmarkUpdateFormSchema = z.object({
  title: z.string({ message: "Title is required" }),
  description: z.string().optional(),
  notes: z.string().optional(),
  groupIds: z.string().array().optional(),
  tagIds: z.string().array().optional(),
})

type BookmarkUpdateFormValues = z.infer<typeof bookmarkUpdateFormSchema>

export const BookmarkEditScreen = () => {
  const { bookmarkId } = useParams<BookmarkEditParams>()
  const [updateBookmark, updateBookmarkResult] = useSafeMutation(BOOKMARK_UPDATE_MUTATION)
  const [showAddGroups, setShowAddGroups] = useState(false)
  const [deletedPills, setDeletedPills] = useState<Array<string>>([])
  const [addedGroups, setAddedGroups] = useState<Array<{ id: string; name: string }>>([])
  const { toast } = useToast()
  const navigate = useNavigate()

  const {
    data: bookmarkData,
    loading: isBookmarkLoading,
    error: bookmarkError,
  } = useQuery(BOOKMARK_QUERY, {
    variables: { bookmarkId: bookmarkId || "" },
  })
  const bookmark = gqlMatchOptional(bookmarkData?.bookmark, "Bookmark")
  const form = useForm<BookmarkUpdateFormValues>({
    resolver: zodResolver(bookmarkUpdateFormSchema),
    defaultValues: {
      title: bookmark?.title || "",
      description: bookmark?.enrichment?.description || "",
      notes: bookmark?.notes || "",
    },
  })

  useEffect(() => {
    if (!isBookmarkLoading && bookmark) {
      form.setValue("title", bookmark.title)
      form.setValue("description", bookmark.enrichment?.description || "")
      form.setValue("notes", bookmark.notes || "")
      form.setValue(
        "groupIds",
        bookmark.groups?.map((g) => g.id)
      )
      if (bookmark.enrichment.tags) {
        form.setValue(
          "tagIds",
          bookmark.enrichment.tags.nodes.map((t) => t.id)
        )
      }
    }
  }, [bookmark, isBookmarkLoading, form])

  useFormErrors(form.setError, updateBookmarkResult)

  const removePill = (fieldName: "groupIds" | "tagIds", id: string) => {
    const currentIds = form.getValues(fieldName)
    if (!currentIds) {
      console.error(`Trying to remove ${fieldName} ids, but no ids set`)
      return
    }
    form.setValue(
      fieldName,
      currentIds.filter((cId) => cId != id)
    )
    setDeletedPills([...deletedPills, id])
  }

  const addNewGroup = (id: string, name: string) => {
    setAddedGroups([...addedGroups, { id: id, name: name }])
  }

  const removeNewGroup = (id: string) => {
    const currentIds = form.getValues("groupIds")
    if (currentIds?.includes(id)) {
      removePill("groupIds", id)
    }
    setAddedGroups(addedGroups.filter((cGroup) => cGroup.id != id))
  }

  const undeletePill = (fieldName: "groupIds" | "tagIds", id: string) => {
    const currentIds = form.getValues(fieldName) || []
    form.setValue(fieldName, [...currentIds, id])
    setDeletedPills(deletedPills.filter((cId) => cId != id))
  }

  const onSubmit = async (values: BookmarkUpdateFormValues) => {
    if (!bookmark?.id) {
      console.error("bookmark undefined. Cannot submit")
      toast({
        title: "Unexpected page error",
        description: "please reload page and try again",
        variant: "destructive",
      })
      return
    }
    const result = await updateBookmark({
      variables: {
        input: {
          id: bookmark?.id,
          bookmarkInput: {
            title: values.title,
            notes: values.notes,
            bookmarkEnrichment: {
              description: values.description,
              tagIds: values.tagIds,
            },
            groupIds: values.groupIds,
          },
        },
      },
    })

    if (result.errors) {
      toast({
        title: "Bookmark Failed to Update",
        description: result.errors.map((e) => e.message).join(", "),
        variant: "destructive",
      })
      return
    }
    toast({
      title: "Bookmark Updated",
      description: "Bookmark has been successfully updated",
      variant: "default",
    })
    navigate(bookmarkDetailPath({ bookmarkId: bookmark.id }))
  }

  return isBookmarkLoading ? (
    <div>Loading...</div>
  ) : bookmarkError ? (
    <div>Error: {JSON.stringify(bookmarkError)}</div>
  ) : !bookmark ? (
    <div>Failed to load Bookmark</div>
  ) : (
    <div className="grid grid-cols-12">
      <div className="col-span-12 mb-8">
        <div className="flex justify-between text-xs">
          <Link to={bookmarkDetailPath({ bookmarkId: bookmark.id })}>
            {"<"} <span className="text-gray-500">Back To Bookmark</span>
          </Link>
        </div>
      </div>
      <div className="col-span-12">
        <h3 className="mb-2 text-lg font-bold">Edit Bookmark</h3>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <FloatingTextField control={form.control} name="title" label="Title" />
            <FloatingTextField control={form.control} name="description" label="Description" />
            <FloatingTextField control={form.control} name="notes" label="Optional Notes" />
            <PillField label="Tags">
              <div className="mt-2 flex justify-start space-x-2">
                {bookmark?.enrichment?.tags &&
                  bookmark.enrichment.tags.nodes.length > 0 &&
                  bookmark.enrichment.tags.nodes.map((tag) => (
                    <Pill
                      key={tag.id}
                      name={tag.name}
                      onDelete={() => removePill("tagIds", tag.id)}
                      isDeleted={deletedPills.includes(tag.id)}
                      onAdd={() => undeletePill("tagIds", tag.id)}
                    />
                  ))}
              </div>
            </PillField>
            <PillField
              label="Groups"
              className="relative"
              onClick={() => setShowAddGroups(!showAddGroups)}
            >
              <span className="float-right">
                <button
                  type="button"
                  className="text-xs font-medium text-gray-400"
                  onClick={() => setShowAddGroups(!showAddGroups)}
                >
                  {showAddGroups ? <span>&#9650;</span> : <span>&#9660;</span>}
                </button>
                {showAddGroups && (
                  <div
                    className="absolute rounded-sm border bg-gray-50 px-2 py-2"
                    style={{ minWidth: 200, right: 8 }}
                  >
                    <GroupsCheckboxes
                      onCheckedChange={(checked, groupChanged) => {
                        if (checked) {
                          addNewGroup(groupChanged.id, groupChanged.name)
                        } else {
                          removeNewGroup(groupChanged.id)
                        }
                      }}
                    />
                    <div className="mt-2 text-xs italic text-gray-500">
                      Hit "Update" to add group
                    </div>
                  </div>
                )}
              </span>
              <div className="mt-2 flex justify-start space-x-2">
                {bookmark.groups?.map((group) => (
                  <Pill
                    key={group.id}
                    name={group.name}
                    onDelete={() => removePill("groupIds", group.id)}
                    isDeleted={deletedPills.includes(group.id)}
                    onAdd={() => undeletePill("groupIds", group.id)}
                  />
                ))}
                {addedGroups.map((group) => (
                  <Pill
                    key={group.id}
                    name={group.name}
                    onDelete={() => removeNewGroup(group.id)}
                    className="bg-yellow-500"
                  />
                ))}
              </div>
            </PillField>

            <Button type="submit" className="w-full" disabled={updateBookmarkResult.loading}>
              {updateBookmarkResult.loading ? "Updating..." : "Update"}
            </Button>
          </form>
        </Form>
      </div>
    </div>
  )
}
