import { gql } from "~/__generated__"
import { UserAvatarFragment } from "~/__generated__/graphql"
import { Avatar, AvatarFallback, AvatarImage, AvatarProps } from "~/ui/avatar"

gql(`
  fragment UserAvatar on User {
    id
    firstName
    lastName
    avatarThumbUrl
  }
`)

export const UserAvatar = ({
  user,
  alt,
  ...rest
}: {
  user: UserAvatarFragment
  alt?: string
} & AvatarProps) => {
  return (
    <Avatar {...rest}>
      {user.avatarThumbUrl && <AvatarImage src={user.avatarThumbUrl} alt={alt} />}
      <AvatarFallback>
        {user.firstName[0].toLocaleUpperCase()}
        {user.lastName[0].toLocaleUpperCase()}
      </AvatarFallback>
    </Avatar>
  )
}
