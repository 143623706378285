import { gql } from "~/__generated__"

export const LEAVE_GROUP_MUTATION = gql(/* GraphQL */ `
  mutation LeaveGroupMutation($input: LeaveGroupInput!) {
    leaveGroup(input: $input) {
      success
    }
  }
`)

export const REMOVE_GROUP_MEMBER_MUTATION = gql(/* GraphQL */ `
  mutation RemoveGroupMemberMutation($input: RemoveGroupMemberInput!) {
    removeGroupMember(input: $input) {
      success
    }
  }
`)
