import { ApolloError, isApolloError } from "@apollo/client"
import { getGraphqlErrors, ValidationError, ValidationErrorPayload } from "./validations"
import { GraphQLError, GraphQLFormattedError } from "graphql"

export function getGraphqlErrorMessages(
  graphqlErrors: readonly (GraphQLFormattedError | GraphQLError)[]
): string | null {
  const validationError = graphqlErrors?.find(
    (error) => error.extensions?.code === "VALIDATION_ERROR"
  )

  if (validationError && validationError.extensions) {
    const typedValidationError = validationError as unknown as ValidationErrorPayload
    return typedValidationError.extensions.validationErrors
      .map((ve: ValidationError) => ve.fullMessage)
      .join(", ")
  }

  return null
}

export function getErrorMessage(error: Error | ApolloError | undefined): string {
  if (!error) return ""

  if (isApolloError(error)) {
    const graphqlErrors = getGraphqlErrors(error)
    const errorMessages = graphqlErrors ? getGraphqlErrorMessages(graphqlErrors) : null
    if (errorMessages) return errorMessages
  }

  return error.message || "An unknown error occurred"
}
