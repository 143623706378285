import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "~/ui/form"
import { Control, FieldPath, FieldValues } from "react-hook-form"
import { InputHTMLAttributes } from "react"
import { cn } from "~/common/cn"
import { FloatingInput } from "~/ui/floating-input"

export const FloatingTextField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  label,
  labelClassName,
  type = "text",
  placeholder,
  disabled,
  required,
  ...rest
}: {
  label: string
  labelClassName?: string
  control: Control<TFieldValues> | undefined
  name: TName
  type?: string
  placeholder?: string
  disabled?: boolean
  required?: boolean
} & InputHTMLAttributes<HTMLInputElement>) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <FormItem className="rounded border px-2 pb-2 pt-1">
          <FormLabel
            className={cn(
              "text-xs text-gray-400",
              fieldState.error && "text-destructive",
              labelClassName
            )}
          >
            {label}
            {required && <span className="text-destructive">*</span>}
          </FormLabel>
          <FormControl>
            <FloatingInput
              {...field}
              {...rest}
              type={type}
              placeholder={placeholder}
              disabled={disabled}
            />
          </FormControl>
          <FormMessage className="text-xs" />
        </FormItem>
      )}
    />
  )
}
