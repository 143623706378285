import { path } from "static-path"

export const credentialsLoginPath = path("/login/credentials")
export const bookmarkEditPath = path("/bookmarks/:bookmarkId/edit")
export const bookmarkDetailPath = path("/bookmarks/:bookmarkId")
export const bookmarksPath = path("/bookmarks")
export const dashboardPath = path("/dashboard")
export const groupsPath = path("/groups")
export const detailsPath = path("/details")
export const emailAuthPath = path("/auth/email/:email/:token/:clientAuthCode")
export const groupDetailPath = path("/group/:id")
export const historyPath = path("/history")
export const invitePath = path("/invite/:inviteToken")
export const loginPath = path("/login")
export const profilePath = path("/profile")
export const profileEditPath = path("/profile/edit")
export const rootPath = path("/")
export const signupPath = path("/signup")
export const privacyPath = path("/privacy")

/** @deprecated */
export const todoPath = path("/TODO")

export const adminExclusionRulesPath = path("/admin/exclusion-rules")
export const adminBookmarksPath = path("/admin/bookmarks")
export const adminUsersPath = path("/admin/users")
export const adminGroupsPath = path("/admin/groups")
export const adminGroupDetailPath = path("/admin/groups/:id")
export const adminUserDetailPath = path("/admin/users/:id")
