import { useEffect, useState } from "react"
import { LoadingSpinner } from "./loading-spinner"

interface DelayedLoadingSpinnerProps {
  delay?: number
  className?: string
}

export const DelayedLoadingSpinner = ({ delay = 300, className }: DelayedLoadingSpinnerProps) => {
  const [showSpinner, setShowSpinner] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSpinner(true)
    }, delay)

    return () => clearTimeout(timer)
  }, [delay])

  if (!showSpinner) return null

  return <LoadingSpinner className={className} />
}

export const CenteredLoadingSpinner = ({ className }: { className?: string }) => {
  return (
    <div className="flex h-full w-full items-center justify-center py-4">
      <LoadingSpinner className={className} />
    </div>
  )
}
