import { useApolloClient } from "@apollo/client"
import { CredentialResponse, GoogleLogin } from "@react-oauth/google"
import { useNavigate } from "react-router-dom"
import { gql } from "~/__generated__"
import { useViewerMaybe } from "~/auth/use-viewer"
import { createApolloLink } from "~/common/create-apollo-link"
import { rootPath } from "~/common/paths"
import { useSafeMutation } from "~/common/use-safe-mutation"
import { toast } from "~/ui/use-toast"
import { useRef } from "react"
import { useParentSize } from "@cutting/use-get-parent-size"

const AUTHENTICATE_WITH_GOOGLE = gql(/* GraphQL */ `
  mutation AuthenticateWithGoogle($credential: String!, $timeZone: String!) {
    authenticateWithGoogle(input: { credential: $credential, timeZone: $timeZone }) {
      success
      csrfToken
    }
  }
`)

const GoogleOAuthButton = () => {
  const navigate = useNavigate()
  const {
    result: { refetch: viewerRefetch },
  } = useViewerMaybe()
  const apolloClient = useApolloClient()
  const [authenticateWithGoogle] = useSafeMutation(AUTHENTICATE_WITH_GOOGLE)
  const ref = useRef<HTMLDivElement>(null)
  const { width } = useParentSize(ref)
  console.log("width: ", width)

  const handleSuccess = async (response: CredentialResponse) => {
    try {
      if (!response.credential) {
        throw new Error("response missing credential")
      }

      const result = await authenticateWithGoogle({
        variables: {
          credential: response.credential,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      })

      if (result.data?.authenticateWithGoogle.success) {
        apolloClient.link = createApolloLink(result.data.authenticateWithGoogle.csrfToken)
        viewerRefetch()
        toast({
          title: "Authentication Successful",
          description: "You have been successfully logged in.",
          variant: "default",
        })
        navigate(rootPath({}))
      } else {
        console.error("Login Error: ", result)
        toast({
          title: "Authentication Failed",
          description: "Unable to authenticate. Please try again.",
          variant: "destructive",
        })
      }
    } catch (error) {
      console.error("Authentication failed", error)
      toast({
        title: "Authentication Failed",
        description: "Unable to authenticate. Please try again.",
        variant: "destructive",
      })
    }
  }

  const handleFailure = () => {
    toast({
      title: "Authentication Failed",
      description: "Unable to authenticate. Please try again.",
      variant: "destructive",
    })
  }

  return (
    <div className="flex w-full justify-center" ref={ref}>
      <GoogleLogin
        width={width}
        auto_select={false}
        onSuccess={handleSuccess}
        onError={handleFailure}
      />
    </div>
  )
}

export default GoogleOAuthButton
