import { useQuery } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { useDebounce } from "use-debounce"
import { gql } from "~/__generated__"
import { useViewer } from "~/auth/use-viewer"
import { BookmarkIndexItem } from "~/bookmarks/bookmark-index-item"
import { gqlMatchOptional } from "~/common/gql-match"
import { groupsPath } from "~/common/paths"
import { bookmarkSearchSchema, bookmarkSearchSerializer } from "~/common/search-schema"
import { useSafeMutation } from "~/common/use-safe-mutation"
import trash from "~/images/trash"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "~/ui/alert-dialog"
import { Button } from "~/ui/button"
import { CenteredLoadingSpinner, DelayedLoadingSpinner } from "~/ui/delayed-loading-spinner"
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "~/ui/dialog"
import { GraphqlError } from "~/ui/errors"
import { Form, FormControl, FormField, FormItem } from "~/ui/form"
import { HoverCard, HoverCardContent, HoverCardTrigger } from "~/ui/hover-card"
import { Input } from "~/ui/input"
import { Section } from "~/ui/section"
import { useToast } from "~/ui/use-toast"
import { UserAvatar } from "~/users/user-avatar"
import { GroupForm } from "./group-form"
import { LEAVE_GROUP_MUTATION, REMOVE_GROUP_MEMBER_MUTATION } from "./group-mutations"
import { useCopyShareLink } from "./use-copy-share-link"
import { Tag } from "~/bookmarks/bookmark-index-screen"
import FilteredTagList from "~/ui/filtered-tags-list"

const GROUP_BOOKMARKS_QUERY = gql(/* GraphQL */ `
  query GroupBookmarks($id: ID!, $after: String, $filters: BookmarkFiltersInput) {
    node(id: $id) {
      __typename
      ... on Group {
        id
        bookmarks(first: 50, after: $after, filters: $filters) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              ...BookmarkIndexItem
            }
          }
        }
      }
    }
  }
`)

const GROUP_QUERY = gql(/* GraphQL */ `
  query GroupDetail($id: ID!) {
    node(id: $id) {
      __typename
      ... on Group {
        id
        name
        description
        shareToken
        canUpdate {
          value
        }
        canManageMembers {
          value
        }
        members(first: 50) {
          edges {
            bookmarksCount
            node {
              id
              firstName
              lastName
              avatarThumbUrl
              ...UserAvatar
            }
          }
        }
      }
    }
  }
`)

export const GroupDetailScreen = () => {
  const { id } = useParams()
  const { viewer } = useViewer()
  const navigate = useNavigate()
  const copyShareLink = useCopyShareLink()
  const [filterTags, setFilterTags] = useState<Array<Tag>>([])
  const { toast } = useToast()
  const [removeGroupMember] = useSafeMutation(REMOVE_GROUP_MEMBER_MUTATION, {
    refetchQueries: ["GroupDetail"],
  })
  const [leaveGroup] = useSafeMutation(LEAVE_GROUP_MUTATION, {
    refetchQueries: ["GroupsQuery"],
    onError: (error) => {
      const isLastAdmin = (error.graphQLErrors as any)?.[0]?.extensions?.details?.group?.includes(
        "last_admin"
      )

      toast({
        title: "Error",
        description: isLastAdmin
          ? "You are the last admin in this group. You need to promote another user to be admin or remove all members before you can leave."
          : error.message,
        variant: "destructive",
      })
    },
  })
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [leaveGroupDialog, setLeaveGroupDialog] = useState<{
    id: string
    name: string
  } | null>(null)
  const [removeUserDialog, setRemoveUserDialog] = useState<{
    id: string
    name: string
  } | null>(null)
  invariant(id, "id is required")

  const [searchParams, setSearchParams] = useSearchParams()

  const form = useForm({
    resolver: zodResolver(bookmarkSearchSchema),
    defaultValues: bookmarkSearchSerializer.lenientParse(searchParams, { search: "" }),
  })
  const { watch } = form

  const searchValue = watch("search")
  const [searchValueDebounced] = useDebounce(searchValue, 300)

  useEffect(() => {
    const subscription = watch((values) => {
      // @ts-expect-error anonying type for 'values' from react-hook-form
      setSearchParams(bookmarkSearchSerializer.serialize(values), { replace: true })
    })
    return () => subscription.unsubscribe()
  }, [watch, setSearchParams])

  const filterTagIds = () => {
    return filterTags.map((tag) => tag.id)
  }

  const toggleFilterTag = (tag: Tag) => {
    if (filterTagIds().includes(tag.id)) {
      setFilterTags(filterTags.filter((existingTag) => existingTag.id != tag.id))
    } else {
      setFilterTags([...filterTags, tag])
    }
    refetch()
  }

  const {
    data: groupData,
    previousData: previousGroupData,
    loading: groupLoading,
    error: groupError,
  } = useQuery(GROUP_QUERY, {
    variables: { id },
  })

  const {
    data: bookmarksData,
    previousData: previousBookmarksData,
    loading: bookmarksLoading,
    error: bookmarksError,
    fetchMore,
    refetch,
  } = useQuery(GROUP_BOOKMARKS_QUERY, {
    variables: {
      id,
      after: null,
      filters: { search: searchValueDebounced || null, tags: filterTagIds() },
    },
  })

  if (groupError || bookmarksError) {
    return <GraphqlError error={groupError ?? bookmarksError!} />
  }

  if ((groupLoading && !previousGroupData) || (bookmarksLoading && !previousBookmarksData)) {
    return <CenteredLoadingSpinner />
  }

  const group = gqlMatchOptional(groupData?.node ?? previousGroupData?.node, "Group")
  const bookmarksGroup = gqlMatchOptional(
    bookmarksData?.node ?? previousBookmarksData?.node,
    "Group"
  )

  if (!group) {
    return (
      <Section>
        <h1 className="text-2xl font-semibold">Group not found</h1>
      </Section>
    )
  }

  const bookmarks = bookmarksGroup?.bookmarks.edges.map((edge) => edge.node) ?? []

  return (
    <Section>
      <div className="mb-6 flex items-center justify-between">
        <div className="flex items-center gap-4">
          <Link to={groupsPath({})} className="text-gray-600 hover:text-gray-900">
            ← All Groups
          </Link>
        </div>
        <div className="flex gap-2">
          <Button variant="ghost" onClick={() => copyShareLink(group.shareToken, viewer.id)}>
            🔗 Copy link
          </Button>
          <Button
            variant="ghost"
            onClick={() => setLeaveGroupDialog({ id: group.id, name: group.name })}
          >
            Leave
          </Button>
          {group.canUpdate.value && (
            <Dialog open={editDialogOpen} onOpenChange={setEditDialogOpen}>
              <DialogTrigger asChild>
                <Button variant="ghost">✏️ Edit</Button>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Edit Group</DialogTitle>
                </DialogHeader>
                <GroupForm
                  groupId={group.id}
                  initialName={group.name}
                  initialDescription={group.description || ""}
                  onSuccess={() => {
                    setEditDialogOpen(false)
                  }}
                  onClose={() => setEditDialogOpen(false)}
                />
              </DialogContent>
            </Dialog>
          )}
        </div>
      </div>
      <div className="mb-6">
        <h1 className="text-2xl font-semibold">{group.name}</h1>
        {group.description && <p className="text-sm text-gray-500">{group.description}</p>}
      </div>

      <div className="mb-6 flex items-center gap-4 border-b pb-6">
        <div className="text-xs text-muted-foreground">Members</div>
        <div className="flex flex-wrap gap-1">
          {group.members.edges.map(({ node, bookmarksCount }) => (
            <HoverCard key={node.id} openDelay={0}>
              <HoverCardTrigger asChild>
                <div>
                  <UserAvatar user={node} size="sm" />
                </div>
              </HoverCardTrigger>
              <HoverCardContent className="w-[167px] break-words rounded-2xl p-3">
                <div className="mb-2 flex justify-center pb-1 pt-2">
                  <UserAvatar user={node} size="lg" />
                </div>
                <div className="">
                  <div className="mb-4 text-center font-medium">
                    {group.canManageMembers.value && viewer.id !== node.id && (
                      <Button
                        variant="ghost"
                        size="sm"
                        className="absolute right-2 top-2 h-6 w-6 rounded-full p-0"
                        onClick={() => {
                          setRemoveUserDialog({
                            id: node.id,
                            name: `${node.firstName} ${node.lastName}`,
                          })
                        }}
                      >
                        <img {...trash} alt="Remove" />
                      </Button>
                    )}
                    {node.firstName} {node.lastName}
                  </div>
                  <div className="my-2 h-px bg-border" />
                  <div className="text-sm text-muted-foreground">
                    <span className="font-medium text-primary">{bookmarksCount}</span>{" "}
                    {bookmarksCount === 1 ? "bookmark" : "bookmarks"}
                  </div>
                </div>
              </HoverCardContent>
            </HoverCard>
          ))}
        </div>
      </div>
      <div className="mb-4">
        <Form {...form}>
          <form className="space-y-4">
            <FormField
              control={form.control}
              name="search"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input type="search" placeholder="Search bookmarks..." {...field} />
                  </FormControl>
                </FormItem>
              )}
            />
          </form>
        </Form>
      </div>

      {/* Filter Tags */}
      <FilteredTagList filterTags={filterTags} toggleFilterTag={toggleFilterTag} />

      {bookmarksLoading && <DelayedLoadingSpinner />}
      {bookmarks.length ? (
        <ul>
          {bookmarks.map((bookmark) => (
            <BookmarkIndexItem
              key={bookmark.id}
              bookmark={bookmark}
              refetch={refetch}
              filterTagIds={filterTagIds()}
              toggleFilterTag={toggleFilterTag}
            />
          ))}
        </ul>
      ) : (
        <p>
          {searchValueDebounced
            ? `No bookmarks found matching "${searchValueDebounced}"`
            : "No bookmarks in this group yet"}
        </p>
      )}
      {bookmarksGroup?.bookmarks.pageInfo.hasNextPage && (
        <div className="mt-4 flex justify-center">
          <Button
            variant="outline"
            onClick={() => {
              fetchMore({
                variables: {
                  after: bookmarksGroup.bookmarks.pageInfo.endCursor,
                },
              })
            }}
          >
            Load more
          </Button>
        </div>
      )}

      <Dialog open={!!leaveGroupDialog} onOpenChange={() => setLeaveGroupDialog(null)}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Are you sure you want to leave group:</DialogTitle>
          </DialogHeader>
          <div className="rounded-md bg-gray-100 p-4">{leaveGroupDialog?.name}</div>
          <div className="flex flex-col gap-2">
            <Button
              variant="default"
              className="w-full"
              onClick={async () => {
                if (!leaveGroupDialog) return

                const { errors } = await leaveGroup({
                  variables: {
                    input: {
                      groupId: leaveGroupDialog.id,
                    },
                  },
                })

                if (!errors) {
                  toast({
                    title: "Success",
                    description: `Left group "${leaveGroupDialog.name}"`,
                  })
                  setLeaveGroupDialog(null)
                  navigate(groupsPath({}))
                }
              }}
            >
              Leave Group
            </Button>
            <DialogClose asChild>
              <Button variant="ghost" className="w-full">
                Close &amp; Cancel
              </Button>
            </DialogClose>
          </div>
        </DialogContent>
      </Dialog>

      <AlertDialog open={!!removeUserDialog} onOpenChange={() => setRemoveUserDialog(null)}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Remove member from group</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to remove {removeUserDialog?.name} from this group? This action
              cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={async () => {
                if (!removeUserDialog) return

                const { errors } = await removeGroupMember({
                  variables: {
                    input: {
                      groupId: group.id,
                      userId: removeUserDialog.id,
                    },
                  },
                })

                if (!errors) {
                  toast({
                    title: "Success",
                    description: `Removed "${removeUserDialog.name}" from group`,
                  })
                  setRemoveUserDialog(null)
                }
              }}
            >
              Remove Member
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Section>
  )
}
