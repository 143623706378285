import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/use-safe-mutation"
import { Button } from "~/ui/button"
import { DialogClose } from "~/ui/dialog"
import { Form, FormControl, FormField, FormItem, FormLabel } from "~/ui/form"
import { Input } from "~/ui/input"
import { useToast } from "~/ui/use-toast"

const exclusionRuleFormSchema = z.object({
  value: z.string().min(1, "Value is required"),
})

type ExclusionRuleFormValues = z.infer<typeof exclusionRuleFormSchema>

const EXCLUSION_RULE_CREATE_MUTATION = gql(/* GraphQL */ `
  mutation ExclusionRuleCreate($input: ExclusionRuleCreateInput!) {
    exclusionRuleCreate(input: $input) {
      exclusionRule {
        id
        value
      }
    }
  }
`)

interface ExclusionRuleFormProps {
  onSuccess: () => void
  onClose: () => void
}

export const ExclusionRuleForm: React.FC<ExclusionRuleFormProps> = ({ onSuccess, onClose }) => {
  const { toast } = useToast()
  const [createExclusionRule] = useSafeMutation(EXCLUSION_RULE_CREATE_MUTATION)

  const form = useForm<ExclusionRuleFormValues>({
    resolver: zodResolver(exclusionRuleFormSchema),
    defaultValues: {
      value: "",
    },
  })

  const onSubmit = async (values: ExclusionRuleFormValues) => {
    const { errors } = await createExclusionRule({
      variables: {
        input: {
          exclusionRuleInput: {
            value: values.value,
          },
        },
      },
    })

    if (!errors) {
      toast({
        title: "Success",
        description: "Exclusion rule created successfully",
      })
      onSuccess()
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <FormField
          control={form.control}
          name="value"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Host</FormLabel>
              <FormControl>
                <Input {...field} placeholder="www.example.com" />
              </FormControl>
              <div className="text-sm text-gray-600">
                Enter a host like "drive.google.com" or "mail.google.com"
              </div>
            </FormItem>
          )}
        />
        <div className="flex justify-end space-x-2">
          <DialogClose asChild>
            <Button type="button" variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </DialogClose>
          <Button type="submit">Create Rule</Button>
        </div>
      </form>
    </Form>
  )
}
