import React, { useCallback, useState, useRef } from "react"
import { gql } from "~/__generated__"
import { useViewer } from "~/auth/use-viewer"
import { useSafeMutation } from "~/common/use-safe-mutation"
import { directImageUpload } from "~/common/direct-image-upload"
import { useToast } from "~/ui/use-toast"
import { Button } from "~/ui/button"
import { UserAvatar } from "~/users/user-avatar"

const userUpdateAvatarMutation = gql(/* GraphQL */ `
  mutation UserUpdate($input: UserUpdateInput!) {
    userUpdate(input: $input) {
      user {
        id
        avatarThumbUrl
      }
    }
  }
`)

export const AvatarUpload = ({ userId }: { userId: string }) => {
  const [isSaving, setIsSaving] = useState(false)
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const { viewer, refetch: refetchViewer } = useViewer()
  const { toast } = useToast()

  const [runUserUpdate] = useSafeMutation(userUpdateAvatarMutation)

  const onFileSelect = useCallback(
    async (file: File) => {
      setIsSaving(true)

      const { signedId } = await directImageUpload(file)

      const { errors } = await runUserUpdate({
        variables: {
          input: {
            id: userId,
            userInput: {},
            avatarSignedId: signedId,
          },
        },
      })

      if (errors) {
        toast({
          title: "Failed to save avatar",
          variant: "destructive",
        })
      } else {
        refetchViewer()
      }

      setIsSaving(false)
    },
    [runUserUpdate, userId]
  )

  const removeAvatar = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    try {
      const { errors } = await runUserUpdate({
        variables: {
          input: {
            id: userId,
            userInput: {},
            removeAvatar: true,
          },
        },
      })

      if (errors) {
        toast({
          title: "Failed to remove avatar",
          variant: "destructive",
        })
      }
    } catch {
      toast({
        title: "Error removing avatar",
        variant: "destructive",
      })
    }
  }

  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const cta = viewer.avatarThumbUrl ? "Replace Photo" : "Add Photo"

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        className="hidden"
        accept="image/jpeg, image/png"
        onChange={(event) => {
          if (event.target.files && event.target.files.length > 0) {
            onFileSelect(event.target.files[0])
          }
        }}
      />
      <div className="">
        <div className="relative">
          <UserAvatar user={viewer} className="mx-auto h-24 w-24" />
          {viewer.avatarThumbUrl && (
            <button
              className="absolute h-8 w-8 rounded-full border bg-white text-xs text-gray-600"
              onClick={removeAvatar}
              style={{ bottom: "70px", left: "85px" }}
            >
              &times;
            </button>
          )}
        </div>

        <Button className="mt-4" onClick={onClick}>
          {isSaving ? "Saving …" : cta}
        </Button>
      </div>
    </>
  )
}
