import { Section } from "~/ui/section"

export const PrivacyScreen = () => {
  return (
    <Section>
      <div className="prose mx-auto max-w-4xl">
        <h1>Privacy Policy</h1>

        <p className="text-sm text-gray-600">
          <strong>Effective Date:</strong> Jan 21, 2025
        </p>

        <p>
          Tasti ("we," "our," or "us") provides a social bookmarking service (the "Service") through
          our Google Chrome extension (the "Plugin"). We value your privacy and are committed to
          protecting your personal information. This Privacy Policy explains how we collect, use,
          and share information about you when you use our Plugin and Service.
        </p>

        <h3>1. Information We Collect</h3>

        <h4>a. Information You Provide</h4>
        <ul>
          <li>
            <strong>Account Information:</strong> If you create an account, we may collect your
            name, email address, and username.
          </li>
          <li>
            <strong>Bookmarks and Tags:</strong> When you save bookmarks or tags, we store this data
            to provide the Service.
          </li>
        </ul>

        <h4>b. Information Collected Automatically</h4>
        <ul>
          <li>
            <strong>Usage Data:</strong> We may collect information about how you interact with the
            Plugin, including the websites you bookmark, timestamps, and click data.
          </li>
          <li>
            <strong>Device Information:</strong> We may collect information about your device,
            browser type, and operating system.
          </li>
        </ul>

        <h4>c. Cookies and Similar Technologies</h4>
        <p>
          We may use cookies and similar technologies to enhance your experience and gather
          analytics about Plugin usage.
        </p>

        <h3>2. How We Use Your Information</h3>
        <p>We use the information we collect for the following purposes:</p>
        <ul>
          <li>To provide and improve the Service.</li>
          <li>To personalize your experience, including suggested bookmarks or tags.</li>
          <li>To analyze Plugin usage and improve performance.</li>
          <li>
            To communicate with you about updates, support, or promotional offers (you may opt out
            of promotional communications).
          </li>
        </ul>

        <h3>3. How We Share Your Information</h3>
        <p>
          We do not sell your personal information. We may share your information in the following
          ways:
        </p>

        <h4>a. With Your Consent</h4>
        <p>We may share your information with third parties if you give us explicit consent.</p>

        <h4>b. Service Providers</h4>
        <p>
          We may share information with trusted third-party service providers who assist us in
          operating the Service, such as hosting providers or analytics tools. These providers are
          bound by confidentiality agreements.
        </p>

        <h4>c. Legal Obligations</h4>
        <p>
          We may disclose your information if required by law, regulation, or legal process, such as
          in response to a court order.
        </p>

        <h3>4. Your Choices</h3>
        <ul>
          <li>
            <strong>Access and Update Your Information:</strong> You can access and update your
            account information through the Plugin.
          </li>
          <li>
            <strong>Delete Your Data:</strong> You can request that we delete your account and
            associated data by contacting us at [Insert Contact Email].
          </li>
          <li>
            <strong>Cookie Preferences:</strong> You can manage cookie preferences through your
            browser settings.
          </li>
        </ul>

        <h3>5. Data Security</h3>
        <p>
          We implement reasonable administrative, technical, and physical security measures to
          protect your personal information. However, no method of transmission or storage is 100%
          secure, and we cannot guarantee absolute security.
        </p>

        <h3>6. Third-Party Links</h3>
        <p>
          The Plugin may contain links to third-party websites or services. We are not responsible
          for the privacy practices or content of those third parties. Please review their privacy
          policies before engaging with them.
        </p>

        <h3>7. International Users</h3>
        <p>
          If you are accessing the Service from outside the United States, please note that your
          information may be transferred to, stored, and processed in the United States, where our
          servers are located. By using the Service, you consent to such transfers.
        </p>

        <h3>8. Changes to This Privacy Policy</h3>
        <p>
          We may update this Privacy Policy from time to time. If we make significant changes, we
          will notify you by posting the updated policy on our website or through the Plugin. Your
          continued use of the Service after the changes take effect constitutes acceptance of the
          updated policy.
        </p>

        <h3>9. Contact Us</h3>
        <p>
          If you have any questions or concerns about this Privacy Policy or our data practices,
          please contact us at:
        </p>

        <p>
          Tasti
          <br />
          1006 Kearny St, San Francisco, CA 94133
          <br />
          <a href="mailto:tasti@lessintech.co">tasti@lessintech.co</a>
        </p>

        <p className="text-sm text-gray-600">
          This Privacy Policy is designed to comply with applicable data protection laws, including
          the General Data Protection Regulation (GDPR) and the California Consumer Privacy Act
          (CCPA), where applicable.
        </p>
      </div>
    </Section>
  )
}
