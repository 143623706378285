import { useNavigate } from "react-router-dom"
import { gql } from "~/__generated__"
import { groupDetailPath } from "~/common/paths"
import { useSafeMutation } from "~/common/use-safe-mutation"
import { useToast } from "~/ui/use-toast"

const JOIN_GROUP_MUTATION = gql(/* GraphQL */ `
  mutation JoinGroup($input: JoinGroupInput!) {
    joinGroup(input: $input) {
      group {
        id
        name
      }
    }
  }
`)

export const useJoinGroup = () => {
  const [joinGroup, { loading }] = useSafeMutation(JOIN_GROUP_MUTATION)
  const { toast } = useToast()
  const navigate = useNavigate()

  const handleJoinGroup = async (shareToken: string, invitedById?: string | null) => {
    const result = await joinGroup({
      variables: {
        input: {
          shareToken,
          invitedById: invitedById ?? undefined,
        },
      },
    })

    if (!result.data) {
      toast({
        title: "Error joining group",
        description: result.errors?.[0].message ?? "An unknown error occurred",
        variant: "destructive",
      })
      return false
    }

    toast({
      title: "Joined group!",
      description: `You've joined ${result.data.joinGroup.group.name}`,
    })
    navigate(groupDetailPath({ id: result.data.joinGroup.group.id }))
    return true
  }

  return {
    joinGroup: handleJoinGroup,
    loading,
  }
}
