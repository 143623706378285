/**
 * ensures that the given optional object is of the given type, or null
 */
export function gqlMatchOptional<T extends { __typename: string }, U extends T["__typename"]>(
  objectOptional: T | null | undefined,
  typename: U
): Extract<T, { __typename: U }> | null {
  if (objectOptional == null) return null
  if (objectOptional.__typename !== typename)
    throw new Error(`Expected ${typename} but got ${objectOptional.__typename}`)
  return objectOptional as Extract<T, { __typename: U }>
}
