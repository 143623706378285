import { useQuery } from "@apollo/client"
import React, { useState } from "react"
import { Link as RouterLink, useParams } from "react-router-dom"
import { gql } from "~/__generated__"
import { getErrorMessage } from "~/common/get-error-message"
import { adminUserDetailPath, bookmarkDetailPath } from "~/common/paths"
import { useSafeMutation } from "~/common/use-safe-mutation"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "~/ui/alert-dialog"
import { Button } from "~/ui/button"
import { CenteredLoadingSpinner } from "~/ui/delayed-loading-spinner"
import { GraphqlError } from "~/ui/errors"
import { Section } from "~/ui/section"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "~/ui/table"
import { useToast } from "~/ui/use-toast"

const DELETE_BOOKMARK_MUTATION = gql(/* GraphQL */ `
  mutation BookmarkDelete($input: BookmarkDeleteInput!) {
    bookmarkDelete(input: $input) {
      success
    }
  }
`)

const REMOVE_GROUP_BOOKMARK_MUTATION = gql(/* GraphQL */ `
  mutation RemoveGroupBookmark($input: RemoveGroupBookmarkInput!) {
    removeGroupBookmark(input: $input) {
      success
    }
  }
`)

const ADMIN_GROUP_QUERY = gql(/* GraphQL */ `
  query AdminGroup($id: ID!) {
    node(id: $id) {
      __typename
      ... on Group {
        id
        name
        createdAt
        creator {
          id
          fullName
        }
        bookmarks(first: 100) {
          edges {
            node {
              id
              title
              url
            }
          }
        }
        members(first: 100) {
          edges {
            role
            invitedBy {
              id
              fullName
            }
            node {
              id
              fullName
              email
            }
          }
        }
      }
    }
  }
`)

const REMOVE_GROUP_MEMBER_MUTATION = gql(/* GraphQL */ `
  mutation RemoveGroupMember($input: RemoveGroupMemberInput!) {
    removeGroupMember(input: $input) {
      success
    }
  }
`)

export const AdminGroupDetailScreen: React.FC = () => {
  const [removeUserDialog, setRemoveUserDialog] = useState<{
    id: string
    name: string
  } | null>(null)
  const [removeBookmarkDialog, setRemoveBookmarkDialog] = useState<{
    id: string
    title: string
  } | null>(null)
  const [deleteBookmarkDialog, setDeleteBookmarkDialog] = useState<{
    id: string
    title: string
  } | null>(null)
  const [removeGroupMember] = useSafeMutation(REMOVE_GROUP_MEMBER_MUTATION, {
    refetchQueries: ["AdminGroup"],
    onError: (error) => {
      toast({
        title: "Error",
        description: getErrorMessage(error),
        variant: "destructive",
      })
    },
  })
  const [removeGroupBookmark] = useSafeMutation(REMOVE_GROUP_BOOKMARK_MUTATION, {
    refetchQueries: ["AdminGroup"],
  })
  const [deleteBookmark] = useSafeMutation(DELETE_BOOKMARK_MUTATION, {
    refetchQueries: ["AdminGroup"],
  })
  const { toast } = useToast()
  const { id } = useParams<{ id: string }>()
  const { data, loading, error } = useQuery(ADMIN_GROUP_QUERY, {
    variables: { id: id ?? "" },
    skip: !id,
  })

  if (error) {
    return <GraphqlError error={error} />
  }

  if (loading || !data?.node || data.node.__typename !== "Group") {
    return <CenteredLoadingSpinner />
  }

  const group = data.node
  const memberEdges = group.members.edges
  const bookmarks = group.bookmarks.edges.map((edge) => edge.node)

  return (
    <>
      <Section className="p-4">
        <div className="mb-8">
          <h1 className="text-2xl font-semibold">{group.name}</h1>
          <div className="mt-2 text-gray-600">
            Created {new Date(group.createdAt).toLocaleString()} by{" "}
            <RouterLink
              to={adminUserDetailPath({ id: group.creator.id })}
              className="text-blue-600 hover:underline"
            >
              {group.creator.fullName}
            </RouterLink>
          </div>
        </div>

        <div className="mb-8">
          <h2 className="mb-4 text-xl font-semibold">Members</h2>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Name</TableHead>
                <TableHead>Email</TableHead>
                <TableHead>Role</TableHead>
                <TableHead>Invited By</TableHead>
                <TableHead>Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {memberEdges.map((edge) => (
                <TableRow key={edge.node.id}>
                  <TableCell>
                    <RouterLink
                      to={adminUserDetailPath({ id: edge.node.id })}
                      className="text-blue-600 hover:underline"
                    >
                      {edge.node.fullName}
                    </RouterLink>
                  </TableCell>
                  <TableCell>{edge.node.email}</TableCell>
                  <TableCell>{edge.role}</TableCell>
                  <TableCell>
                    {edge.invitedBy && (
                      <RouterLink
                        to={adminUserDetailPath({ id: edge.invitedBy.id })}
                        className="text-blue-600 hover:underline"
                      >
                        {edge.invitedBy.fullName}
                      </RouterLink>
                    )}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={() => {
                        setRemoveUserDialog({
                          id: edge.node.id,
                          name: `${edge.node.fullName}`,
                        })
                      }}
                    >
                      Remove
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>

        <div>
          <h2 className="mb-4 text-xl font-semibold">Bookmarks</h2>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>ID</TableHead>
                <TableHead>Title</TableHead>
                <TableHead>URL</TableHead>
                <TableHead>Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {bookmarks.map((bookmark) => (
                <TableRow key={bookmark.id}>
                  <TableCell>
                    <a
                      href={bookmarkDetailPath({ bookmarkId: bookmark.id })}
                      className="text-blue-600 hover:underline"
                      target="_blank"
                    >
                      {bookmark.id}
                    </a>
                  </TableCell>
                  <TableCell>{bookmark.title}</TableCell>
                  <TableCell>
                    <a
                      href={bookmark.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:underline"
                    >
                      {bookmark.url}
                    </a>
                  </TableCell>
                  <TableCell>
                    <div className="flex gap-2">
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => {
                          setRemoveBookmarkDialog({
                            id: bookmark.id,
                            title: bookmark.title,
                          })
                        }}
                      >
                        Remove from group
                      </Button>
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => {
                          setDeleteBookmarkDialog({
                            id: bookmark.id,
                            title: bookmark.title,
                          })
                        }}
                      >
                        Delete bookmark
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Section>

      <AlertDialog open={!!removeUserDialog} onOpenChange={() => setRemoveUserDialog(null)}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Remove member from group</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to remove {removeUserDialog?.name} from this group? This action
              cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={async () => {
                if (!removeUserDialog) return

                const { errors } = await removeGroupMember({
                  variables: {
                    input: {
                      groupId: group.id,
                      userId: removeUserDialog.id,
                    },
                  },
                })

                if (!errors) {
                  toast({
                    title: "Success",
                    description: `Removed "${removeUserDialog.name}" from group`,
                  })
                  setRemoveUserDialog(null)
                }
              }}
            >
              Remove Member
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog open={!!removeBookmarkDialog} onOpenChange={() => setRemoveBookmarkDialog(null)}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Remove bookmark from group</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to remove this bookmark from the group? This action cannot be
              undone.
              <div className="mt-2 rounded-md bg-gray-100 p-4">{removeBookmarkDialog?.title}</div>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={async () => {
                if (!removeBookmarkDialog) return

                const { errors } = await removeGroupBookmark({
                  variables: {
                    input: {
                      groupId: group.id,
                      bookmarkId: removeBookmarkDialog.id,
                    },
                  },
                })

                if (!errors) {
                  toast({
                    title: "Success",
                    description: `Removed "${removeBookmarkDialog.title}" from group`,
                  })
                  setRemoveBookmarkDialog(null)
                }
              }}
            >
              Remove Bookmark
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog open={!!deleteBookmarkDialog} onOpenChange={() => setDeleteBookmarkDialog(null)}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Delete bookmark</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to delete this bookmark? This action cannot be undone.
              <div className="mt-2 rounded-md bg-gray-100 p-4">{deleteBookmarkDialog?.title}</div>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={async () => {
                if (!deleteBookmarkDialog) return

                const { errors } = await deleteBookmark({
                  variables: {
                    input: {
                      bookmarkId: deleteBookmarkDialog.id,
                    },
                  },
                })

                if (!errors) {
                  toast({
                    title: "Success",
                    description: `Deleted bookmark "${deleteBookmarkDialog.title}"`,
                  })
                  setDeleteBookmarkDialog(null)
                }
              }}
            >
              Delete Bookmark
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}
