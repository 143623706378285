import React, { FC } from "react"
import { cn } from "~/common/cn"

export interface PillParams {
  name: string
  onDelete?: () => void
  onAdd?: () => void
  onClick?: (arg: any) => void
  isDeleted?: boolean
  className?: string
}

const Pill: FC<PillParams> = ({ className, isDeleted, name, onDelete, onAdd, onClick = null }) => {
  const handleOnDelete = (e: React.SyntheticEvent<HTMLElement>) => {
    if (onDelete) {
      e.stopPropagation()
      onDelete()
    }
  }

  const handleOnAdd = (e: React.SyntheticEvent<HTMLElement>) => {
    if (onAdd) {
      e.stopPropagation()
      onAdd()
    }
  }

  const handleOnClick = (e: React.SyntheticEvent<HTMLElement>) => {
    if (onClick) {
      e.stopPropagation()
      onClick(e)
    }
  }

  return (
    <span
      className={cn(
        isDeleted ? "bg-red-600 line-through" : "bg-blue-600",
        onClick ? "cursor-pointer" : "",
        "h-8 whitespace-nowrap rounded-full px-4 py-2 text-xs text-white",
        className
      )}
      onClick={handleOnClick}
    >
      {name}
      {isDeleted && onAdd ? (
        <button type="button" className={cn("pl-1")} onClick={handleOnAdd}>
          +
        </button>
      ) : onDelete ? (
        <button type="button" className={cn("pl-1")} onClick={handleOnDelete}>
          &times;
        </button>
      ) : null}
    </span>
  )
}

export default Pill
