import { Link } from "react-router-dom"
import { rootPath } from "~/common/paths"
import logoIcon from "~/images/logo-icon"

const LoggedOutHeader = () => {
  return (
    <header className="sticky top-0">
      <div className="mx-auto px-4">
        <div className="flex h-16 items-center justify-between">
          <div className="flex items-center space-x-6">
            <Link className="flex items-center space-x-2" to={rootPath({})}>
              <img {...logoIcon} alt="Logo" />
              <span className="font-bold">Tasti</span>
            </Link>
          </div>
        </div>
      </div>
    </header>
  )
}

export default LoggedOutHeader
