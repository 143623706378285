import { useQuery } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { Link, useSearchParams } from "react-router-dom"
import { useDebounce } from "use-debounce"
import { z } from "zod"
import { gql } from "~/__generated__"
import { adminUserDetailPath } from "~/common/paths"
import { Button } from "~/ui/button"
import { CenteredLoadingSpinner } from "~/ui/delayed-loading-spinner"
import { GraphqlError } from "~/ui/errors"
import { Form, FormControl, FormField, FormItem } from "~/ui/form"
import { Input } from "~/ui/input"
import { Section } from "~/ui/section"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "~/ui/table"

const adminUserSearchSchema = z.object({
  search: z.string(),
})

const adminUserSearchSerializer = {
  serialize: (values: z.infer<typeof adminUserSearchSchema>) => {
    const params = new URLSearchParams()
    if (values.search) params.set("q", values.search)
    return params
  },
  lenientParse: (
    searchParams: URLSearchParams,
    defaultValues: z.infer<typeof adminUserSearchSchema>
  ) => {
    return {
      search: searchParams.get("q") ?? defaultValues.search,
    }
  },
}

const ADMIN_USERS_QUERY = gql(/* GraphQL */ `
  query AdminUsers($after: String, $first: Int!, $filters: UserFiltersInput) {
    users(first: $first, after: $after, filters: $filters) {
      edges {
        node {
          id
          email
          firstName
          lastName
          userStatus
          userRole
          createdAt
          bookmarksCount
          organizations {
            nodes {
              name
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`)

export const AdminUsersScreen: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const form = useForm({
    resolver: zodResolver(adminUserSearchSchema),
    defaultValues: adminUserSearchSerializer.lenientParse(searchParams, { search: "" }),
  })

  const { watch } = form
  const searchValue = watch("search")
  const [searchValueDebounced] = useDebounce(searchValue, 300)

  useEffect(() => {
    const subscription = watch((values) => {
      // @ts-expect-error annoying type for 'values' from react-hook-form
      setSearchParams(adminUserSearchSerializer.serialize(values), { replace: true })
    })
    return () => subscription.unsubscribe()
  }, [watch, setSearchParams])

  const { data, previousData, loading, error, fetchMore } = useQuery(ADMIN_USERS_QUERY, {
    variables: {
      first: 50,
      after: null,
      filters: { search: searchValueDebounced || null },
    },
  })

  if (error) {
    return <GraphqlError error={error} />
  }

  if (loading && !data && !previousData) {
    return <CenteredLoadingSpinner />
  }

  const users = (data || previousData)?.users.edges.map((edge) => edge.node) ?? []

  return (
    <Section className="p-4">
      <div className="mb-6">
        <h1 className="text-2xl font-semibold">Users</h1>
        <div className="mt-4">
          <Form {...form}>
            <form className="space-y-4">
              <FormField
                control={form.control}
                name="search"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input type="search" placeholder="Search users..." {...field} />
                    </FormControl>
                  </FormItem>
                )}
              />
            </form>
          </Form>
        </div>
      </div>

      {users.length ? (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Name</TableHead>
              <TableHead>Email</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Role</TableHead>
              <TableHead>Organizations</TableHead>
              <TableHead>Bookmarks</TableHead>
              <TableHead>Created At</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id}>
                <TableCell>
                  <Link
                    to={adminUserDetailPath({ id: user.id })}
                    className="text-blue-600 hover:underline"
                  >
                    {user.firstName} {user.lastName}
                  </Link>
                </TableCell>
                <TableCell>
                  <a href={`mailto:${user.email}`}>{user.email}</a>
                </TableCell>
                <TableCell>{user.userStatus}</TableCell>
                <TableCell>{user.userRole}</TableCell>
                <TableCell>{user.organizations.nodes.map((org) => org.name).join(", ")}</TableCell>
                <TableCell>{user.bookmarksCount}</TableCell>
                <TableCell>{new Date(user.createdAt).toLocaleString()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <p>No users found</p>
      )}

      {data?.users.pageInfo.hasNextPage && (
        <div className="mt-4 flex justify-center">
          <Button
            variant="outline"
            onClick={() => {
              fetchMore({
                variables: {
                  after: data.users.pageInfo.endCursor,
                },
              })
            }}
          >
            Load more
          </Button>
        </div>
      )}
    </Section>
  )
}
