import { useQuery } from "@apollo/client"
import { FC } from "react"
import { useFormContext } from "react-hook-form"
import { gql } from "~/__generated__"
import { Checkbox } from "~/ui/checkbox"
import { LoadingSpinner } from "~/ui/loading-spinner"

const groupsQuery = gql(/* GraphQL */ `
  query GroupsCheckboxes {
    groups(first: 100, filters: { preset: MEMBER }) {
      nodes {
        id
        name
      }
    }
  }
`)

type GroupCheckboxesParams = {
  onCheckedChange?: (checked: boolean, group: { id: string; name: string }) => void
}

const GroupsCheckboxes: FC<GroupCheckboxesParams> = ({ onCheckedChange }) => {
  const { data, loading } = useQuery(groupsQuery)
  const { setValue, watch } = useFormContext()
  const selectedGroupIds = watch("groupIds") as string[]

  if (loading) return <LoadingSpinner className="h-4 w-4" />

  const groups = data?.groups?.nodes || []

  return (
    <div className="space-y-2">
      {groups.map((group) => (
        <div key={group.id} className="flex items-center space-x-2">
          <Checkbox
            id={group.id}
            checked={selectedGroupIds?.includes(group.id)}
            onCheckedChange={(checked: boolean) => {
              const currentValue = selectedGroupIds || []
              const newValue = checked
                ? [...currentValue, group.id]
                : currentValue.filter((id: string) => id !== group.id)
              setValue("groupIds", newValue)
              if (onCheckedChange) {
                onCheckedChange(checked, group)
              }
            }}
          />
          <label
            htmlFor={group.id}
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            {group.name}
          </label>
        </div>
      ))}
    </div>
  )
}

export default GroupsCheckboxes
