import { useQuery } from "@apollo/client"
import { useEffect } from "react"
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import { useViewerMaybe } from "~/auth/use-viewer"
import { groupsPath, loginPath, rootPath, signupPath } from "~/common/paths"
import { Button } from "~/ui/button"
import { GraphqlError } from "~/ui/errors"
import { LinkButton } from "~/ui/link-button"
import { LoadingSpinner } from "~/ui/loading-spinner"
import { Section } from "~/ui/section"
import { useGroupInvitationStorage } from "./use-group-invitation-storage"
import { useJoinGroup } from "./use-join-group"

const GROUP_INVITATION_QUERY = gql(/* GraphQL */ `
  query GroupInvitation($shareToken: String!) {
    groupInvitation(shareToken: $shareToken) {
      id
      name
      isMember
    }
  }
`)

export const InviteScreen = () => {
  const { inviteToken } = useParams()
  invariant(inviteToken, "inviteToken is required")
  const { data, error, loading } = useQuery(GROUP_INVITATION_QUERY, {
    variables: { shareToken: inviteToken },
  })

  const { result: viewerResult } = useViewerMaybe()
  const { setGroupInvitation } = useGroupInvitationStorage()
  const [searchParams] = useSearchParams()
  const inviterId = searchParams.get("inviter")
  const { joinGroup, loading: joiningGroup } = useJoinGroup()

  useEffect(() => {
    if (joiningGroup) {
      return
    }

    if (
      viewerResult.data?.viewer &&
      data &&
      data.groupInvitation &&
      !data.groupInvitation.isMember
    ) {
      joinGroup(inviteToken, inviterId)
    }
  }, [data, inviteToken, joinGroup, joiningGroup, viewerResult.data?.viewer])

  const navigate = useNavigate()
  const handleAuthNavigation = (path: string) => {
    invariant(data, "data is required")
    invariant(data.groupInvitation, "groupInvitation is required")
    setGroupInvitation({
      shareToken: inviteToken,
      inviterId: inviterId ?? undefined,
    })
    navigate(path)
  }

  if (loading || viewerResult.loading || joiningGroup) {
    return (
      <Section>
        <LoadingSpinner />
        {joiningGroup && <p className="mt-4">Joining group...</p>}
      </Section>
    )
  }

  invariant(data, "data is required")

  if (error) {
    return <GraphqlError error={error} />
  }

  if (data.groupInvitation?.isMember) {
    return (
      <Section>
        <h1 className="mb-4 text-2xl font-semibold">Already a Member</h1>
        <p className="mb-6">
          You are already a member of <strong>{data.groupInvitation.name}</strong>
        </p>
        <div className="flex gap-4">
          <LinkButton to={groupsPath({})}>Back</LinkButton>
        </div>
      </Section>
    )
  }

  if (!data?.groupInvitation) {
    return (
      <Section>
        <h1 className="mb-4 text-2xl font-semibold">Invalid Invitation</h1>
        <p className="mb-6">
          This invitation link is not valid. Please request a new invitation link from the person
          who invited you.
        </p>
        <div className="flex gap-4">
          <LinkButton to={rootPath({})}>Back</LinkButton>
        </div>
      </Section>
    )
  }

  return (
    <Section>
      <h1 className="mb-4 text-2xl font-semibold">Group Invitation</h1>
      <p className="mb-6">
        You've been invited to join <strong>{data.groupInvitation.name}</strong>
      </p>
      <div className="flex gap-4">
        <Button onClick={() => handleAuthNavigation(signupPath({}))}>Sign up</Button>
        <Button variant="outline" onClick={() => handleAuthNavigation(loginPath({}))}>
          Log in
        </Button>
        <Button variant="ghost" asChild>
          <Link to={rootPath({})}>Back</Link>
        </Button>
      </div>
    </Section>
  )
}
