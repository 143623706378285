import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "~/ui/form"
import { Control, FieldPath, FieldValues } from "react-hook-form"
import { InputHTMLAttributes } from "react"
import { cva } from "class-variance-authority"
import { cn } from "~/common/cn"
import { Input } from "../ui/input"

const textFieldVariants = cva("", {
  variants: {
    variant: {
      default: "rounded border px-2 pb-2 pt-1",
      borderless: "",
    },
  },
  defaultVariants: {
    variant: "default",
  },
})

export const TextField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  label,
  labelClassName,
  type = "text",
  placeholder,
  disabled,
  required,
  variant,
  ...rest
}: {
  label: string
  labelClassName?: string
  control: Control<TFieldValues> | undefined
  name: TName
  type?: string
  placeholder?: string
  disabled?: boolean
  required?: boolean
  variant?: "default" | "borderless"
} & InputHTMLAttributes<HTMLInputElement>) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <FormItem className={textFieldVariants({ variant })}>
          <FormLabel
            className={cn(
              "text-xs text-gray-400",
              fieldState.error && "text-destructive",
              labelClassName
            )}
          >
            {label}
            {required && <span className="text-destructive">*</span>}
          </FormLabel>
          <FormControl>
            <Input {...field} {...rest} type={type} placeholder={placeholder} disabled={disabled} />
          </FormControl>
          <FormMessage className="text-xs" />
        </FormItem>
      )}
    />
  )
}
