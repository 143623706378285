import { Outlet } from "react-router-dom"
import { LoggedOutFooter } from "~/footers/logged-out-footer"
import LoggedOutHeader from "~/headers/logged-out-header"

/**
 * doesn't incldue navigation etc, intended mostly for pages where the user is not logged in
 */
export const MinimalLayout = () => {
  return (
    <div className="flex min-h-screen flex-col">
      <LoggedOutHeader />

      <main className="flex-1 overflow-y-auto p-4">
        <div className="mx-auto w-full max-w-7xl px-4">
          <Outlet />
        </div>
      </main>

      <LoggedOutFooter />
    </div>
  )
}
