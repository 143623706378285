import { FC, ReactNode } from "react"
import { cn } from "~/common/cn"

export interface PillFieldParams {
  children: ReactNode
  label: string
  required?: boolean
  className?: string
  labelClassName?: string
  onClick?: () => void
}

export const PillField: FC<PillFieldParams> = ({
  children,
  label,
  required,
  className,
  labelClassName,
  onClick = () => {},
}) => {
  return (
    <div className={cn(className, "rounded border px-2 pb-2 pt-1")} onClick={onClick}>
      <label
        className={cn(
          labelClassName,
          "text-xs font-medium text-gray-400 peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
        )}
      >
        {label}
        {required && <span className="text-destructive">*</span>}
      </label>
      {children}
    </div>
  )
}
