import { useQuery } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { Link, useSearchParams } from "react-router-dom"
import { useDebounce } from "use-debounce"
import { z } from "zod"
import { gql } from "~/__generated__"
import { useViewer } from "~/auth/use-viewer"
import { adminGroupDetailPath } from "~/common/paths"
import { useCopyShareLink } from "~/groups/use-copy-share-link"
import { Button } from "~/ui/button"
import { CenteredLoadingSpinner } from "~/ui/delayed-loading-spinner"
import { GraphqlError } from "~/ui/errors"
import { Form, FormControl, FormField, FormItem } from "~/ui/form"
import { Input } from "~/ui/input"
import { Section } from "~/ui/section"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "~/ui/table"

const adminGroupSearchSchema = z.object({
  search: z.string(),
})

const adminGroupSearchSerializer = {
  serialize: (values: z.infer<typeof adminGroupSearchSchema>) => {
    const params = new URLSearchParams()
    if (values.search) params.set("q", values.search)
    return params
  },
  lenientParse: (
    searchParams: URLSearchParams,
    defaultValues: z.infer<typeof adminGroupSearchSchema>
  ) => {
    return {
      search: searchParams.get("q") ?? defaultValues.search,
    }
  },
}

const ADMIN_GROUPS_QUERY = gql(/* GraphQL */ `
  query AdminGroups($after: String, $first: Int!, $filters: GroupFiltersInput) {
    groups(first: $first, after: $after, filters: $filters) {
      edges {
        node {
          id
          name
          membersCount
          bookmarksCount
          shareToken
          creator {
            id
            fullName
          }
          createdAt
          updatedAt
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`)

export const AdminGroupsScreen: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { viewer } = useViewer()

  const form = useForm({
    resolver: zodResolver(adminGroupSearchSchema),
    defaultValues: adminGroupSearchSerializer.lenientParse(searchParams, { search: "" }),
  })

  const { watch } = form
  const searchValue = watch("search")
  const [searchValueDebounced] = useDebounce(searchValue, 300)

  useEffect(() => {
    const subscription = watch((values) => {
      // @ts-expect-error annoying type for 'values' from react-hook-form
      setSearchParams(adminGroupSearchSerializer.serialize(values), { replace: true })
    })
    return () => subscription.unsubscribe()
  }, [watch, setSearchParams])

  const { data, previousData, loading, error, fetchMore } = useQuery(ADMIN_GROUPS_QUERY, {
    variables: {
      first: 50,
      after: null,
      filters: { search: searchValueDebounced || null },
    },
  })
  const copyShareLink = useCopyShareLink()

  if (error) {
    return <GraphqlError error={error} />
  }

  if (loading && !data && !previousData) {
    return <CenteredLoadingSpinner />
  }

  const groups = (data ?? previousData)?.groups.edges.map((edge) => edge.node) ?? []

  return (
    <Section className="p-4">
      <div className="mb-6">
        <h1 className="text-2xl font-semibold">Groups</h1>
        <div className="mt-4">
          <Form {...form}>
            <form className="space-y-4">
              <FormField
                control={form.control}
                name="search"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input type="search" placeholder="Search groups..." {...field} />
                    </FormControl>
                  </FormItem>
                )}
              />
            </form>
          </Form>
        </div>
      </div>

      {groups.length ? (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Name</TableHead>
              <TableHead>Creator</TableHead>
              <TableHead>Members</TableHead>
              <TableHead>Bookmarks</TableHead>
              <TableHead>Created At</TableHead>
              <TableHead>Share</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {groups.map((group) => (
              <TableRow key={group.id}>
                <TableCell>
                  <Link
                    to={adminGroupDetailPath({ id: group.id })}
                    className="text-blue-600 hover:underline"
                  >
                    {group.name}
                  </Link>
                </TableCell>
                <TableCell>
                  <Link
                    to={`/admin/users/${group.creator.id}`}
                    className="text-blue-600 hover:underline"
                  >
                    {group.creator.fullName}
                  </Link>
                </TableCell>
                <TableCell>{group.membersCount}</TableCell>
                <TableCell>{group.bookmarksCount}</TableCell>
                <TableCell>{new Date(group.createdAt).toLocaleString()}</TableCell>
                <TableCell>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => {
                      copyShareLink(group.shareToken, viewer.id)
                    }}
                  >
                    Copy Link
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <p>No groups found</p>
      )}

      {data?.groups.pageInfo.hasNextPage && (
        <div className="mt-4 flex justify-center">
          <Button
            variant="outline"
            onClick={() => {
              fetchMore({
                variables: {
                  after: data.groups.pageInfo.endCursor,
                },
              })
            }}
          >
            Load more
          </Button>
        </div>
      )}
    </Section>
  )
}
