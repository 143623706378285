import { useEffect, useState } from "react"
import { gql } from "~/__generated__"
import { BookmarkEnrichmentScreenshotCardImageFragment } from "~/__generated__/graphql"
import { CenteredLoadingSpinner } from "~/ui/delayed-loading-spinner"
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "~/ui/dialog"

gql(/* GraphQL */ `
  fragment BookmarkEnrichmentScreenshotCardImage on BookmarkEnrichment {
    id
    card2x: screenshotUrl(variant: "card_2x")
    full: screenshotUrl
    title
  }
`)

export const BookmarkEnrichmentScreenshotCardImage = ({
  enrichment,
}: {
  enrichment: BookmarkEnrichmentScreenshotCardImageFragment
}) => {
  const [showFullImage, setShowFullImage] = useState(false)
  const [isFullImageLoading, setIsFullImageLoading] = useState(true)

  useEffect(() => {
    if (showFullImage) {
      setIsFullImageLoading(true)
    }
  }, [showFullImage])

  if (!enrichment.card2x) return null

  return (
    <>
      <button onClick={() => setShowFullImage(true)}>
        <img
          src={enrichment.card2x}
          alt="Page screenshot"
          className="flex rounded-lg border object-cover transition-opacity hover:opacity-90"
          width={150}
          height={100}
        />
      </button>

      <Dialog open={showFullImage} onOpenChange={setShowFullImage}>
        <DialogContent className="max-h-[90vh] max-w-4xl overflow-y-auto">
          <DialogHeader>
            <DialogTitle>Screenshot</DialogTitle>
          </DialogHeader>
          <div className="relative">
            {isFullImageLoading && <CenteredLoadingSpinner />}
            {showFullImage && (
              <img
                src={enrichment.full || enrichment.card2x}
                alt="Full page screenshot"
                className="w-full rounded-lg"
                onLoad={() => setIsFullImageLoading(false)}
              />
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}
