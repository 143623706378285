import { useQuery } from "@apollo/client"
import { DelayedLoadingSpinner } from "./delayed-loading-spinner"
import { gql } from "~/__generated__"
import { Dispatch, FC, SetStateAction } from "react"

export interface GroupsFilterParams {
  groupFilter: string | null
  setGroupFilter: Dispatch<SetStateAction<string | null>>
  bookmarksCount: number | undefined
  maxGroupsShown: number
}

const groupsQuery = gql(/* GraphQL */ `
  query GroupsFilters {
    groups(first: 100, filters: { preset: MEMBER, sort: RECENTLY_USED }) {
      nodes {
        id
        description
        name
        bookmarksCount
      }
    }
  }
`)

const inactiveTextStyles = "cursor-pointer pb-4 text-sm text-gray-400"
const inactiveCountStyles =
  "ml-2 inline-flex h-6 w-6 items-center justify-center rounded-full bg-gray-200 text-xs font-medium text-gray-600"
const activeTextStyles = "border-b border-black pb-4 text-sm text-black"
const activeCountStyles =
  "ml-2 inline-flex h-6 w-6 items-center justify-center rounded-full bg-blue-600 text-xs font-medium text-white"

const GroupsFilter: FC<GroupsFilterParams> = ({
  groupFilter,
  setGroupFilter,
  bookmarksCount,
  maxGroupsShown,
}) => {
  const { data: groupsData, loading: groupsLoading } = useQuery(groupsQuery)
  const groups = groupsData?.groups.nodes

  return groupsLoading ? (
    <DelayedLoadingSpinner />
  ) : groups?.length && groups.length > 0 ? (
    <div className="mt-6 flex justify-start space-x-10 border-b">
      <span
        className={groupFilter == null ? activeTextStyles : inactiveTextStyles}
        onClick={() => setGroupFilter(null)}
      >
        All
        <span className={groupFilter == null ? activeCountStyles : inactiveCountStyles}>
          {bookmarksCount}
        </span>
      </span>
      {groups.slice(0, maxGroupsShown).map((group) => (
        <span
          title={group.description || ""}
          className={groupFilter == group.id ? activeTextStyles : inactiveTextStyles}
          onClick={() => setGroupFilter(group.id)}
        >
          {group.name}
          <span className={groupFilter == group.id ? activeCountStyles : inactiveCountStyles}>
            {group.bookmarksCount}
          </span>
        </span>
      ))}
    </div>
  ) : null
}

export default GroupsFilter
