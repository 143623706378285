import { useViewer } from "~/auth/use-viewer"
import pencilIcon from "~/images/pencil-icon"
import { profileEditPath, todoPath } from "~/common/paths"
import { UserAvatar } from "~/users/user-avatar"
import { Link } from "react-router-dom"

export const ProfileScreen = () => {
  const { viewer } = useViewer()

  return (
    <div className="flex flex-1 flex-col">
      <div className="bottom-1 flex items-center justify-between border-b border-gray-300 py-4">
        <h1 className="text-2xl">My Profile</h1>
      </div>
      <div className="mb-4 flex items-center space-x-6 border-b border-gray-300 py-8">
        <div className="flex-none">
          <UserAvatar
            user={viewer}
            alt="A picture that represents you and your account"
            className="h-16 w-16"
          />
        </div>
        <div className="flex-1 font-medium">
          <div className="text-lg">{viewer.fullName}</div>
          <div className="text-xs text-gray-400">{viewer.email}</div>
        </div>
        <div className="flex-none">
          <Link className="text-xs text-gray-400" to={profileEditPath({})}>
            <img {...pencilIcon} className="mr-2 inline-flex" />
            Edit Details
          </Link>
        </div>
      </div>
      <div className="py-4">
        <Link className="text-xs text-gray-400" to={todoPath({})}>
          Delete Account
        </Link>
      </div>
    </div>
  )
}
