import linkIcon from "~/images/link-icon.svg"

import { useState } from "react"
import { Link } from "react-router-dom"
import { gql } from "~/__generated__"
import { BookmarkIndexItemFragment } from "~/__generated__/graphql"
import { useViewer } from "~/auth/use-viewer"
import { bookmarkDetailPath, bookmarkEditPath, groupDetailPath } from "~/common/paths"
import { useSafeMutation } from "~/common/use-safe-mutation"
import Pill from "~/ui/pill"
import { Tag } from "./bookmark-index-screen"
import { RelativeDatetime } from "~/ui/relative-date"
import { toast } from "~/ui/use-toast"
import { UserAvatar } from "~/users/user-avatar"
import { BookmarkEnrichmentScreenshotCardImage } from "./bookmark-enrichment-screenshot-card-image"

const BOOKMARK_DELETE_MUTATION = gql(/* GraphQL */ `
  mutation BookmarkDeleteMutation($input: String!) {
    bookmarkDelete(input: { bookmarkId: $input }) {
      success
    }
  }
`)

gql(/* GraphQL */ `
  fragment BookmarkIndexItem on Bookmark {
    id
    createdAt
    title
    url
    user {
      id
      firstName
      fullName
      lastName
    }
    enrichment {
      id
      description
      ...BookmarkEnrichmentScreenshotCardImage
      tags {
        nodes {
          id
          name
        }
      }
    }
    groups {
      id
      name
    }
  }
`)

type BookmarkIndexItemParams = {
  bookmark: BookmarkIndexItemFragment
  filterTagIds: Array<string>
  toggleFilterTag: (tag: Tag) => void
  refetch: () => Promise<any>
}

export const BookmarkIndexItem: React.FC<BookmarkIndexItemParams> = ({
  bookmark,
  filterTagIds,
  refetch,
  toggleFilterTag,
}) => {
  const { viewer } = useViewer()
  const [showEditModal, setShowEditModal] = useState(false)

  const [deleteBookmark] = useSafeMutation(BOOKMARK_DELETE_MUTATION, {
    onError: (error) => {
      toast({
        title: "Error",
        description: error.message,
        variant: "destructive",
      })
    },
  })

  const handleDeleteBookmark = async () => {
    setShowEditModal(false)
    const confirmResponse = confirm("Are you sure you want to delete this bookmark?")
    if (!confirmResponse) return
    try {
      const deleteResult = await deleteBookmark({ variables: { input: bookmark.id } })
      if (deleteResult.data?.bookmarkDelete?.success) {
        toast({
          title: "Bookmark Deleted",
        })
        refetch()
      } else {
        toast({
          title: "Failed to delete bookmrk",
          description: JSON.stringify(deleteResult.errors),
        })
      }
    } catch (e) {
      console.error("Failed to delete bookmark:", e)
      toast({
        title: "Failed to delete bookmrk",
      })
    }
  }

  return (
    <li className="mt-8 border-t pt-8 first:border-t-0">
      <div className="mb-2 flex text-xs">
        <div className="flex flex-1 space-x-2">
          <div className="">
            <UserAvatar user={bookmark.user} className="h-8 w-8" />
          </div>
          <div className="flex flex-col justify-center align-middle">
            <div>
              {bookmark.groups && bookmark.groups.length > 0 && (
                <span className="mr-1">
                  <span className="mr-1 text-gray-400">In</span>
                  <span>
                    {bookmark.groups.map((group, i) => [
                      i > 0 && ", ",
                      <Link to={groupDetailPath({ id: group.id })} key={group.id}>
                        {group.name}
                      </Link>,
                    ])}
                  </span>
                </span>
              )}
              <span className="text-gray-400">by</span> {bookmark.user?.fullName}
            </div>
          </div>
        </div>
        <div className="relative flex-1 text-right text-gray-400">
          <RelativeDatetime input={bookmark.createdAt} />
          {viewer.id === bookmark.user.id && (
            <button
              className="ml-2 align-text-bottom"
              onClick={() => setShowEditModal(!showEditModal)}
            >
              ...
            </button>
          )}
          {showEditModal && (
            <div
              className="absolute rounded-lg bg-white px-4 py-2 text-left text-sm text-primary shadow-lg"
              style={{ top: "25px", right: "0px" }}
            >
              <ul>
                <li className="border-b py-2">
                  <Link to={bookmarkEditPath({ bookmarkId: bookmark.id })}>Edit Bookmark</Link>
                </li>
                <li className="py-2">
                  <button onClick={handleDeleteBookmark}>Remove</button>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="flex gap-4">
        <div className="flex-1">
          <h2 className="flex space-x-2 text-lg font-medium">
            <Link
              to={bookmarkDetailPath({ bookmarkId: bookmark.id })}
              className="hover:text-blue-500"
            >
              {bookmark.title}
            </Link>
            <a href={bookmark.url} className="self-center">
              <img src={linkIcon} className="rounded-full bg-gray-200" width={18} height={18} />
            </a>
          </h2>
          <p className="text-sm text-gray-400">{bookmark.enrichment.description}</p>
          <div className="mt-6 flex justify-start space-x-2">
            {bookmark?.enrichment?.tags &&
              bookmark.enrichment.tags.nodes.length > 0 &&
              bookmark.enrichment.tags.nodes.map((tag) => (
                <Pill
                  key={tag.id}
                  name={tag.name}
                  onClick={() => toggleFilterTag(tag)}
                  className={filterTagIds.includes(tag.id) ? "" : "border bg-white text-gray-800"}
                />
              ))}
          </div>
        </div>
        <BookmarkEnrichmentScreenshotCardImage enrichment={bookmark.enrichment} />
      </div>
    </li>
  )
}
