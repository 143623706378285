import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/use-safe-mutation"
import { Button } from "~/ui/button"
import { DialogClose } from "~/ui/dialog"
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "~/ui/form"
import { Input } from "~/ui/input"
import { LoadingSpinner } from "~/ui/loading-spinner"
import { useToast } from "~/ui/use-toast"
import { useQuery } from "@apollo/client"

const groupFormSchema = z.object({
  name: z.string().min(1, "Name is required"),
  description: z.string().optional(),
})

export type GroupFormValues = z.infer<typeof groupFormSchema>

const CREATE_GROUP_MUTATION = gql(/* GraphQL */ `
  mutation CreateGroupMutation($input: GroupCreateInput!) {
    groupCreate(input: $input) {
      group {
        id
        name
        description
        membersCount
        bookmarksCount
        shareToken
      }
    }
  }
`)

const UPDATE_GROUP_MUTATION = gql(/* GraphQL */ `
  mutation UpdateGroupMutation($input: GroupUpdateInput!) {
    groupUpdate(input: $input) {
      group {
        id
        name
        membersCount
        bookmarksCount
        shareToken
      }
    }
  }
`)

const EXISTING_GROUP_NAMES_QUERY = gql(/* GraphQL */ `
  query ExistingGroupNamesQuery {
    groups(first: 100) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`)

interface GroupFormProps {
  groupId?: string
  initialName?: string
  initialDescription?: string
  onSuccess: () => void
  onClose: () => void
}

export const GroupForm = ({
  groupId,
  initialName = "",
  initialDescription = "",
  onSuccess,
  onClose,
}: GroupFormProps) => {
  const { toast } = useToast()
  const { data: existingGroupsData } = useQuery(EXISTING_GROUP_NAMES_QUERY)

  const [createGroup, { loading: createLoading }] = useSafeMutation(CREATE_GROUP_MUTATION, {
    refetchQueries: ["GroupsQuery"],
    onError: (error) => {
      toast({
        title: "Error",
        description: error.message,
        variant: "destructive",
      })
    },
  })

  const [updateGroup, { loading: updateLoading }] = useSafeMutation(UPDATE_GROUP_MUTATION, {
    refetchQueries: ["GroupsQuery", "GroupDetail"],
    onError: (error) => {
      toast({
        title: "Error",
        description: error.message,
        variant: "destructive",
      })
    },
  })

  const loading = createLoading || updateLoading

  const onSubmit = async (values: GroupFormValues) => {
    if (groupId) {
      const { errors } = await updateGroup({
        variables: {
          input: {
            id: groupId,
            groupInput: {
              name: values.name,
              description: values.description,
            },
          },
        },
      })

      if (errors) {
        toast({
          title: "Error",
          description: "Failed to update group",
          variant: "destructive",
        })
        return
      }

      toast({
        title: "Success",
        description: "Group updated successfully",
      })
    } else {
      const { errors } = await createGroup({
        variables: {
          input: {
            groupInput: {
              name: values.name,
              description: values.description,
            },
          },
        },
      })

      if (errors) {
        toast({
          title: "Error",
          description: "Failed to create group",
          variant: "destructive",
        })
        return
      }

      toast({
        title: "Success",
        description: "Group created successfully",
      })
    }

    onSuccess()
  }

  const form = useForm<GroupFormValues>({
    resolver: zodResolver(groupFormSchema),
    defaultValues: {
      name: initialName,
      description: initialDescription,
    },
  })

  const existingNames =
    existingGroupsData?.groups.edges.map((e) => e.node.name.toLowerCase().trim()) || []
  const currentName = form.watch("name")
  const hasExistingName = existingNames.includes(currentName.toLowerCase().trim())

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <FormField
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name</FormLabel>
              <FormControl>
                <Input {...field} autoComplete="off" />
              </FormControl>
              <FormMessage />
              {hasExistingName && !groupId && (
                <div className="mt-2 text-sm text-yellow-600">
                  You already belong to a group with this name
                </div>
              )}
            </FormItem>
          )}
        />
        <FormField
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Description</FormLabel>
              <FormControl>
                <Input {...field} autoComplete="off" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button type="submit" className="w-full" disabled={loading}>
          {loading ? <LoadingSpinner className="mr-2 h-4 w-4" /> : null}
          {groupId ? "Update Group" : "Create Group"}
        </Button>
        <DialogClose asChild>
          <Button type="button" variant="ghost" className="mt-2 w-full" onClick={onClose}>
            Close &amp; Cancel
          </Button>
        </DialogClose>
      </form>
    </Form>
  )
}
