import { useQuery } from "@apollo/client"
import React, { useState } from "react"
import { Link, useParams } from "react-router-dom"
import { gql } from "~/__generated__"
import { adminGroupDetailPath, bookmarkDetailPath } from "~/common/paths"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "~/ui/alert-dialog"
import { Button } from "~/ui/button"
import { CenteredLoadingSpinner } from "~/ui/delayed-loading-spinner"
import { GraphqlError } from "~/ui/errors"
import { Section } from "~/ui/section"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "~/ui/table"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "~/ui/tooltip"
import { UserAvatar } from "~/users/user-avatar"

const ADMIN_USER_QUERY = gql(/* GraphQL */ `
  query AdminUser(
    $id: ID!
    $bookmarksFirst: Int!
    $bookmarksAfter: String
    $groupsFirst: Int!
    $groupsAfter: String
  ) {
    node(id: $id) {
      ... on User {
        __typename
        id
        email
        firstName
        lastName
        userStatus
        userRole
        createdAt
        bookmarksCount
        ...UserAvatar
        bookmarks(first: $bookmarksFirst, after: $bookmarksAfter) {
          edges {
            node {
              id
              title
              url
              createdAt
              notes
              dom
              enrichment {
                description
              }
              groups {
                id
                name
              }
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
        groups(first: $groupsFirst, after: $groupsAfter) {
          edges {
            node {
              id
              name
              membersCount
              bookmarksCount
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
  }
`)

export const AdminUserDetailScreen: React.FC = () => {
  const { id } = useParams()
  const { data, loading, error, fetchMore } = useQuery(ADMIN_USER_QUERY, {
    variables: {
      id: id!,
      bookmarksFirst: 30,
      bookmarksAfter: null,
      groupsFirst: 30,
      groupsAfter: null,
    },
  })
  const [bookmarkToDelete, setBookmarkToDelete] = useState<{
    id: string
    title: string
  } | null>(null)

  if (error) {
    return <GraphqlError error={error} />
  }

  if (loading || !data?.node || data.node.__typename !== "User") {
    return <CenteredLoadingSpinner />
  }

  const user = data.node

  return (
    <Section className="p-4">
      <div className="mb-6 flex items-center gap-4">
        <UserAvatar user={user} className="h-16 w-16" />
        <h1 className="text-2xl font-semibold">User Details</h1>
      </div>

      <dl className="mb-8 space-y-4">
        <div>
          <dt className="text-sm font-medium text-gray-500">Name</dt>
          <dd className="mt-1">
            {user.firstName} {user.lastName}
          </dd>
        </div>

        <div>
          <dt className="text-sm font-medium text-gray-500">Email</dt>
          <dd className="mt-1">
            <a href={`mailto:${user.email}`} className="text-blue-600 hover:underline">
              {user.email}
            </a>
          </dd>
        </div>

        <div>
          <dt className="text-sm font-medium text-gray-500">Status</dt>
          <dd className="mt-1">{user.userStatus}</dd>
        </div>

        <div>
          <dt className="text-sm font-medium text-gray-500">Role</dt>
          <dd className="mt-1">{user.userRole}</dd>
        </div>

        <div>
          <dt className="text-sm font-medium text-gray-500">Bookmarks</dt>
          <dd className="mt-1">{user.bookmarksCount}</dd>
        </div>

        <div>
          <dt className="text-sm font-medium text-gray-500">Created</dt>
          <dd className="mt-1">{new Date(user.createdAt).toLocaleString()}</dd>
        </div>
      </dl>

      <div className="mt-8 space-y-8">
        <div>
          <h2 className="mb-4 text-xl font-semibold">Groups</h2>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Name</TableHead>
                <TableHead>Members</TableHead>
                <TableHead>Bookmarks</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {user.groups.edges.map(({ node: group }) => (
                <TableRow key={group.id}>
                  <TableCell>
                    <Link
                      to={adminGroupDetailPath({ id: group.id })}
                      className="text-blue-600 hover:underline"
                    >
                      {group.name}
                    </Link>
                  </TableCell>
                  <TableCell>{group.membersCount}</TableCell>
                  <TableCell>{group.bookmarksCount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {user.groups.pageInfo.hasNextPage && (
            <div className="mt-4 flex justify-center">
              <Button
                variant="outline"
                onClick={() => {
                  fetchMore({
                    variables: {
                      groupsAfter: user.groups.pageInfo.endCursor,
                    },
                  })
                }}
              >
                Load more groups
              </Button>
            </div>
          )}
        </div>
        <div>
          <h2 className="mb-4 text-xl font-semibold">Bookmarks</h2>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>ID</TableHead>
                <TableHead>URL</TableHead>
                <TableHead>Title</TableHead>
                <TableHead>Description</TableHead>
                <TableHead>Groups</TableHead>
                <TableHead>Created At</TableHead>
                <TableHead>Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {user.bookmarks.edges.map(({ node: bookmark }) => (
                <TableRow key={bookmark.id}>
                  <TableCell className="font-mono text-sm">
                    <Link
                      to={bookmarkDetailPath({ bookmarkId: bookmark.id })}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:underline"
                    >
                      {bookmark.id}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <a
                      href={bookmark.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:underline"
                    >
                      {bookmark.url}
                    </a>
                  </TableCell>
                  <TableCell>
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger className="max-w-md truncate">
                          {bookmark.title}
                        </TooltipTrigger>
                        <TooltipContent>{bookmark.title}</TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </TableCell>
                  <TableCell>
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger className="max-w-md truncate">
                          {bookmark.notes ||
                            bookmark.enrichment?.description ||
                            bookmark.dom?.slice(0, 100)}
                        </TooltipTrigger>
                        <TooltipContent>
                          {bookmark.notes ||
                            bookmark.enrichment?.description ||
                            bookmark.dom?.slice(0, 100)}
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </TableCell>
                  <TableCell>
                    {bookmark.groups?.map((group, i) => (
                      <span key={group.id}>
                        {i > 0 && ", "}
                        <Link
                          to={adminGroupDetailPath({ id: group.id })}
                          className="text-blue-600 hover:underline"
                        >
                          {group.name}
                        </Link>
                      </span>
                    ))}
                  </TableCell>
                  <TableCell>{new Date(bookmark.createdAt).toLocaleString()}</TableCell>
                  <TableCell>
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={() =>
                        setBookmarkToDelete({ id: bookmark.id, title: bookmark.title })
                      }
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {user.bookmarks.pageInfo.hasNextPage && (
            <div className="mt-4 flex justify-center">
              <Button
                variant="outline"
                onClick={() => {
                  fetchMore({
                    variables: {
                      bookmarksAfter: user.bookmarks.pageInfo.endCursor,
                    },
                  })
                }}
              >
                Load more bookmarks
              </Button>
            </div>
          )}
        </div>
      </div>

      <AlertDialog open={!!bookmarkToDelete} onOpenChange={() => setBookmarkToDelete(null)}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Delete bookmark</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to delete the bookmark "{bookmarkToDelete?.title}"? This action
              cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={async () => {
                // TODO: Implement bookmark deletion mutation
                setBookmarkToDelete(null)
              }}
            >
              Delete Bookmark
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Section>
  )
}
