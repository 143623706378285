/* eslint-disable @typescript-eslint/no-empty-object-type */
import * as React from "react"
import { useFormField } from "~/ui/form"

import { cn } from "~/common/cn"

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const FloatingInput = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    const { error } = useFormField()
    return (
      <input
        type={type}
        className={cn(
          "flex w-full bg-background text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-neutral-50 disabled:text-neutral-400",
          error && "border-destructive",
          className
        )}
        ref={ref}
        {...props}
      />
    )
  }
)
FloatingInput.displayName = "Input"

export { FloatingInput }
