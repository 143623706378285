import { RouteObject, createBrowserRouter } from "react-router-dom"
import { AdminBookmarksScreen } from "~/admin/admin-bookmarks-screen"
import { AdminUsersScreen } from "~/admin/admin-users-screen"
import { AdminDashboardScreen } from "~/admin/admin-dashboard-screen"
import { AdminExclusionRulesScreen } from "~/admin/admin-exclusion-rules-screen"
import { AdminGroupDetailScreen } from "~/admin/admin-group-detail-screen"
import { AdminGroupsScreen } from "~/admin/admin-groups-screen"
import { AdminUserDetailScreen } from "~/admin/admin-user-detail-screen"
import {
  RequireSystemAdminSignedIn,
  RequireUserSignedIn,
  RequireUserSignedOut,
} from "~/auth/auth-layouts"
import { MinimalLayout } from "~/auth/minimal-layout"
import { PrivacyScreen } from "~/privacy/privacy-screen"
import { BookmarkIndexScreen } from "~/bookmarks/bookmark-index-screen"
import {
  adminBookmarksPath,
  adminExclusionRulesPath,
  adminGroupDetailPath,
  adminGroupsPath,
  adminUserDetailPath,
  adminUsersPath,
  bookmarkDetailPath,
  bookmarkEditPath,
  bookmarksPath,
  credentialsLoginPath,
  dashboardPath,
  emailAuthPath,
  groupDetailPath,
  groupsPath,
  historyPath,
  invitePath,
  loginPath,
  privacyPath,
  profileEditPath,
  profilePath,
  rootPath,
  signupPath,
} from "~/common/paths"
import { GroupDetailScreen } from "~/groups/group-detail-screen"
import { GroupIndexScreen } from "~/groups/group-index-screen"
import { HistoryScreen } from "~/history/history-screen"
import { InviteScreen } from "~/invite/invite-screen"
import { SandwichPageLayout } from "~/layouts/sandwich-page-layout"
import { SidebarLayout } from "~/layouts/sidebar-layout"
import { CredentialsLoginScreen } from "~/login/credentials-login-screen"
import { EmailAuthScreen } from "~/login/email-auth-screen"
import { EmailLoginScreen } from "~/login/email-login-screen"
import { ProfileEditScreen } from "~/profile/profile-edit-screen"
import { ProfileScreen } from "~/profile/profile-screen"
import { SignupScreen } from "~/signup/signup-screen"
import { ErrorBoundary } from "../ui/error-boundary"
import { RootLayout } from "./root-layout"
import { RootScreen } from "./root-screen"
import { BookmarkEditScreen } from "~/bookmarks/bookmark-edit-screen"
import { BookmarkDetailScreen } from "~/bookmarks/bookmark-detail-screen"

const systemAdminAuthenticatedRoutes: Array<RouteObject> = [
  {
    path: dashboardPath.pattern,
    element: <AdminDashboardScreen />,
  },
  {
    path: adminExclusionRulesPath.pattern,
    element: <AdminExclusionRulesScreen />,
  },
  {
    path: adminBookmarksPath.pattern,
    element: <AdminBookmarksScreen />,
  },
  {
    path: adminUsersPath.pattern,
    element: <AdminUsersScreen />,
  },
  {
    path: adminGroupsPath.pattern,
    element: <AdminGroupsScreen />,
  },
  {
    path: adminGroupDetailPath.pattern,
    element: <AdminGroupDetailScreen />,
  },
  {
    path: adminUserDetailPath.pattern,
    element: <AdminUserDetailScreen />,
  },
]

const authenticatedRoutes: Array<RouteObject> = [
  {
    path: bookmarkEditPath.pattern,
    element: <BookmarkEditScreen />,
  },
  {
    path: bookmarkDetailPath.pattern,
    element: <BookmarkDetailScreen />,
  },
  {
    path: bookmarksPath.pattern,
    element: <BookmarkIndexScreen />,
  },
  {
    path: groupsPath.pattern,
    element: <GroupIndexScreen />,
  },
  {
    path: groupDetailPath.pattern,
    element: <GroupDetailScreen />,
  },
  {
    path: historyPath.pattern,
    element: <HistoryScreen />,
  },
  {
    path: profilePath.pattern,
    element: <ProfileScreen />,
  },
  {
    path: profileEditPath.pattern,
    element: <ProfileEditScreen />,
  },
]

export const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        element: <RequireSystemAdminSignedIn />,
        children: [
          {
            element: <SidebarLayout />,
            children: systemAdminAuthenticatedRoutes,
          },
        ],
      },
      {
        element: <RequireUserSignedIn />,
        children: [
          {
            element: <SandwichPageLayout />,
            children: authenticatedRoutes,
          },
        ],
      },
      {
        element: <RequireUserSignedOut />,
        children: [
          {
            element: <MinimalLayout />,
            children: [
              {
                path: signupPath.pattern,
                element: <SignupScreen />,
              },
              {
                path: loginPath.pattern,
                element: <EmailLoginScreen />,
              },
              {
                path: credentialsLoginPath.pattern,
                element: <CredentialsLoginScreen />,
              },
              {
                path: emailAuthPath.pattern,
                element: <EmailAuthScreen />,
              },
            ],
          },
        ],
      },
      {
        element: <MinimalLayout />,
        children: [
          {
            path: invitePath.pattern,
            element: <InviteScreen />,
          },
          {
            path: privacyPath.pattern,
            element: <PrivacyScreen />,
          },
        ],
      },
      {
        path: rootPath.pattern,
        element: <RootScreen />,
      },
    ],
  },
])
