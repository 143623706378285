import { Link, Outlet, ScrollRestoration } from "react-router-dom"
import { UserRole } from "~/__generated__/graphql"
import { useLogout } from "~/auth/use-logout"
import { useViewer } from "~/auth/use-viewer"
import { cn } from "~/common/cn"
import {
  adminBookmarksPath,
  adminExclusionRulesPath,
  adminGroupsPath,
  adminUsersPath,
  rootPath,
} from "~/common/paths"
import gridIcon from "~/images/grid-icon"
import logoutIcon from "~/images/logout-icon"

const sidebarItemStyles = "flex border-b border-gray-300 hover:bg-gray-100 text-sm font-medium"

const SidebarLink = ({ text, to }: { text: string; to: string }) => (
  <li className={sidebarItemStyles}>
    <Link to={to} className="flex flex-1 px-5 py-3">
      <img {...gridIcon} alt="" />
      <div className="pl-4">{text}</div>
    </Link>
  </li>
)

export const SidebarLinks = () => {
  const { viewer } = useViewer()

  return (
    <nav className="mt-5 border-t border-gray-300">
      <ul className="list-none flex-col">
        {viewer.userRole === UserRole.SystemAdmin && (
          <>
            <SidebarLink text={"Bookmarks"} to={adminBookmarksPath({})} />
            <SidebarLink text={"Exclusion rules"} to={adminExclusionRulesPath({})} />
            <SidebarLink text={"Groups"} to={adminGroupsPath({})} />
            <SidebarLink text={"Users"} to={adminUsersPath({})} />
          </>
        )}
      </ul>
    </nav>
  )
}

export const SidebarLayout = () => {
  const logout = useLogout()

  return (
    <div className="flex min-h-screen flex-1 grow">
      <div className="sidebar sticky top-0 flex h-screen w-[200px] flex-col justify-between gap-2 bg-white shadow-md">
        <div>
          <Link to={rootPath({})} className="flex px-4 pt-6">
            ← Leave admin
          </Link>

          <SidebarLinks />
        </div>
        <div className={cn("border-t border-gray-300", sidebarItemStyles)}>
          <button onClick={logout} className="flex w-full flex-1 px-5 py-3">
            <img {...logoutIcon} alt="" />
            <div className="pl-4">Sign Out</div>
          </button>
        </div>
      </div>

      <div className="flex max-w-full flex-1 flex-col overflow-hidden">
        <Outlet />
        <ScrollRestoration />
      </div>
    </div>
  )
}
