import { useQuery } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useSearchParams } from "react-router-dom"
import { useDebounce } from "use-debounce"
import { gql } from "~/__generated__"
import { BookmarkPreset } from "~/__generated__/graphql"
import { bookmarkSearchSchema, bookmarkSearchSerializer } from "~/common/search-schema"
import { Button } from "~/ui/button"
import { DelayedLoadingSpinner } from "~/ui/delayed-loading-spinner"
import { GraphqlError } from "~/ui/errors"
import { Form, FormControl, FormField, FormItem } from "~/ui/form"
import { Input } from "~/ui/input"
import { BookmarkIndexItem } from "../bookmarks/bookmark-index-item"
import { Tag } from "~/bookmarks/bookmark-index-screen"
import FilteredTagList from "~/ui/filtered-tags-list"

const query = gql(/* GraphQL */ `
  query HistoryScreen($after: String, $filters: BookmarkFiltersInput) {
    bookmarks(first: 50, after: $after, filters: $filters) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          ...BookmarkIndexItem
        }
      }
    }
  }
`)

export const HistoryScreen = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [filterTags, setFilterTags] = useState<Array<Tag>>([])

  const form = useForm({
    resolver: zodResolver(bookmarkSearchSchema),
    defaultValues: bookmarkSearchSerializer.lenientParse(searchParams, { search: "" }),
  })

  const { watch } = form
  const searchValue = watch("search")
  const [searchValueDebounced] = useDebounce(searchValue, 300)

  const filterTagIds = () => {
    return filterTags.map((tag) => tag.id)
  }

  const toggleFilterTag = (tag: Tag) => {
    if (filterTagIds().includes(tag.id)) {
      setFilterTags(filterTags.filter((existingTag) => existingTag.id != tag.id))
    } else {
      setFilterTags([...filterTags, tag])
    }
  }

  useEffect(() => {
    const subscription = watch((values) => {
      // @ts-expect-error anonying type for 'values' from react-hook-form
      setSearchParams(bookmarkSearchSerializer.serialize(values), { replace: true })
    })
    return () => subscription.unsubscribe()
  }, [watch, setSearchParams])

  const { data, loading, error, fetchMore, refetch } = useQuery(query, {
    variables: {
      after: null,
      filters: {
        preset: BookmarkPreset.Mine,
        search: searchValueDebounced || null,
        tags: filterTagIds(),
      },
    },
  })

  if (error) {
    return <GraphqlError error={error} />
  }

  const bookmarks = data?.bookmarks.edges.map((e) => e.node) ?? []

  return (
    <div className="grid grid-cols-12">
      <div className="col-span-12">
        <h1 className="text-2xl">History</h1>
        <div className="mb-4">
          <Form {...form}>
            <form className="space-y-4">
              <FormField
                control={form.control}
                name="search"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input type="search" placeholder="Search bookmarks..." {...field} />
                    </FormControl>
                  </FormItem>
                )}
              />
            </form>
          </Form>
        </div>

        {/* Filter Tags */}
        <FilteredTagList filterTags={filterTags} toggleFilterTag={toggleFilterTag} />

        {loading ? <DelayedLoadingSpinner /> : null}
        {bookmarks.length ? (
          <ul>
            {bookmarks.map((bookmark) => (
              <BookmarkIndexItem
                key={bookmark.id}
                bookmark={bookmark}
                refetch={refetch}
                filterTagIds={filterTagIds()}
                toggleFilterTag={toggleFilterTag}
              />
            ))}
          </ul>
        ) : (
          <p>
            {searchValueDebounced
              ? `No bookmarks found matching "${searchValueDebounced}"`
              : "You don't have any saved bookmarks yet"}
          </p>
        )}
        {data?.bookmarks.pageInfo.hasNextPage && (
          <div className="mt-4 flex justify-center">
            <Button
              variant="outline"
              onClick={() => {
                fetchMore({
                  variables: {
                    after: data.bookmarks.pageInfo.endCursor,
                  },
                })
              }}
            >
              Load more
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
