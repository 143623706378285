import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { bookmarksPath, loginPath } from "~/common/paths"
import { LoadingSpinner } from "~/ui/loading-spinner"
import { useViewerMaybe } from "../auth/use-viewer"

export const RootScreen = () => {
  const { viewer, result } = useViewerMaybe()
  const navigate = useNavigate()

  useEffect(() => {
    if (result.loading) {
      return
    }

    if (viewer) {
      navigate(bookmarksPath({}), { replace: true })
    } else {
      navigate(loginPath({}))
    }
  }, [navigate, viewer])

  return (
    <div data-testid="root-screen" className="flex flex-1 items-center justify-center">
      <LoadingSpinner />
    </div>
  )
}
