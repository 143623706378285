import { formatDistanceToNow } from "date-fns"
import { useEffect, useState } from "react"
import { HoverCard, HoverCardContent, HoverCardTrigger } from "./hover-card"

export const RelativeDatetime: React.FC<{ input: string }> = ({ input }) => {
  const [, setTick] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => {
      setTick((t) => t + 1)
    }, 15000)
    return () => clearInterval(timer)
  }, [])

  return (
    <HoverCard>
      <HoverCardTrigger>
        {formatDistanceToNow(new Date(input), { addSuffix: true })}
      </HoverCardTrigger>
      <HoverCardContent className="w-auto text-center">
        {new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "numeric",
          minute: "2-digit",
          timeZoneName: "short",
        }).format(new Date(input))}
      </HoverCardContent>
    </HoverCard>
  )
}
