import React, { useEffect } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { Outlet, useNavigate } from "react-router-dom"
import { UserRole } from "~/__generated__/graphql"
import { loginPath, rootPath } from "~/common/paths"
import { useGroupInvitationStorage } from "~/invite/use-group-invitation-storage"
import { useJoinGroup } from "~/invite/use-join-group"
import { useViewerMaybe } from "./use-viewer"

export const RequireUserSignedOut = () => {
  const navigate = useNavigate()
  const { viewer, result } = useViewerMaybe()

  useEffect(() => {
    if (viewer) {
      navigate(rootPath.pattern)
    }
  }, [viewer, navigate])

  if (result.loading) return null

  return <Outlet />
}

export const RequireUserSignedIn = () => {
  const navigate = useNavigate()
  const { viewer, result } = useViewerMaybe()
  const { getGroupInvitation, clearGroupInvitation } = useGroupInvitationStorage()
  const { joinGroup } = useJoinGroup()

  const hasNavigated = React.useRef(false)
  let pathname = window.location.pathname

  useEffect(() => {
    if (!result.loading && !viewer && !hasNavigated.current) {
      hasNavigated.current = true
      navigate(loginPath({}) + "?" + new URLSearchParams({ returnTo: pathname }))
    }
  }, [navigate, result.loading, viewer, pathname])

  useEffect(() => {
    if (!viewer) return

    const invitation = getGroupInvitation()
    if (!invitation) return

    // Clear the invitation immediately to prevent duplicate joins
    clearGroupInvitation()

    joinGroup(invitation.shareToken, invitation.inviterId)
  }, [viewer, joinGroup])

  if (result.loading) return null

  return (
    <ErrorBoundary fallback={<div>Something went wrong</div>}>
      <Outlet />
    </ErrorBoundary>
  )
}

export const RequireSystemAdminSignedIn = () => {
  const navigate = useNavigate()
  const { viewer, result } = useViewerMaybe()

  useEffect(() => {
    if (viewer?.userRole !== UserRole.SystemAdmin) {
      navigate(rootPath({}))
    }
  }, [navigate, result.loading, viewer])

  if (result.loading) return null

  return <Outlet />
}
