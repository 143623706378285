import { DirectUpload } from "@rails/activestorage"
import { Blob } from "rails__activestorage"
import { getMetaContentMaybe } from "./get-meta-content"

const defaultDirectUploadsUrl = getMetaContentMaybe("direct-uploads-url")

export const directImageUpload = async (
  image: FileList[number],
  uploadsUrl?: string
): Promise<any> => {
  const data = await uploadFile(image, uploadsUrl || defaultDirectUploadsUrl)
  return { signedId: data.signed_id }
}

const uploadFile = (file: File, uploadsUrl: string | null): Promise<Blob> => {
  if (!uploadsUrl) {
    throw new Error("Expected meta tag with name direct-uploads-url to exist")
  }

  return new Promise((resolve, reject) => {
    const upload = new DirectUpload(file, uploadsUrl)

    upload.create((error, blob) => {
      if (error) {
        reject(error)
      } else {
        resolve(blob)
      }
    })
  })
}
