import { useQuery } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { Link, useSearchParams } from "react-router-dom"
import { useDebounce } from "use-debounce"
import { z } from "zod"
import { gql } from "~/__generated__"
import { adminGroupDetailPath, adminUserDetailPath, bookmarkDetailPath } from "~/common/paths"
import { useSafeMutation } from "~/common/use-safe-mutation"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "~/ui/alert-dialog"
import { Button } from "~/ui/button"
import { CenteredLoadingSpinner, DelayedLoadingSpinner } from "~/ui/delayed-loading-spinner"
import { GraphqlError } from "~/ui/errors"
import { Form, FormControl, FormField, FormItem } from "~/ui/form"
import { Input } from "~/ui/input"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "~/ui/table"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "~/ui/tooltip"
import { Popover, PopoverContent, PopoverTrigger } from "~/ui/popover"
import { useToast } from "~/ui/use-toast"

const adminBookmarkSearchSchema = z.object({
  search: z.string(),
})

const adminBookmarkSearchSerializer = {
  serialize: (values: z.infer<typeof adminBookmarkSearchSchema>) => {
    const params = new URLSearchParams()
    if (values.search) params.set("q", values.search)
    return params
  },
  lenientParse: (
    searchParams: URLSearchParams,
    defaultValues: z.infer<typeof adminBookmarkSearchSchema>
  ) => {
    return {
      search: searchParams.get("q") ?? defaultValues.search,
    }
  },
}

const DELETE_BOOKMARK_MUTATION = gql(/* GraphQL */ `
  mutation BookmarkDelete($input: BookmarkDeleteInput!) {
    bookmarkDelete(input: $input) {
      success
    }
  }
`)

const ADMIN_BOOKMARKS_QUERY = gql(/* GraphQL */ `
  query AdminBookmarks($after: String, $filters: BookmarkFiltersInput) {
    bookmarks(first: 50, after: $after, filters: $filters) {
      edges {
        node {
          id
          createdAt
          title
          url
          groups {
            id
            name
          }
          user {
            id
            firstName
            fullName
            lastName
            email
          }
          canDelete {
            value
          }
          enrichment {
            id
            description
            tags {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`)

export const AdminBookmarksScreen: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [deleteBookmarkDialog, setDeleteBookmarkDialog] = useState<{
    id: string
    title: string
  } | null>(null)
  const { toast } = useToast()
  const [deleteBookmark] = useSafeMutation(DELETE_BOOKMARK_MUTATION, {
    refetchQueries: ["AdminBookmarks"],
  })

  const form = useForm({
    resolver: zodResolver(adminBookmarkSearchSchema),
    defaultValues: adminBookmarkSearchSerializer.lenientParse(searchParams, { search: "" }),
  })

  const { watch } = form
  const searchValue = watch("search")
  const [searchValueDebounced] = useDebounce(searchValue, 300)

  useEffect(() => {
    const subscription = watch((values) => {
      // @ts-expect-error annoying type for 'values' from react-hook-form
      setSearchParams(adminBookmarkSearchSerializer.serialize(values), { replace: true })
    })
    return () => subscription.unsubscribe()
  }, [watch, setSearchParams])

  const { data, previousData, loading, error, fetchMore } = useQuery(ADMIN_BOOKMARKS_QUERY, {
    variables: {
      after: null,
      filters: { search: searchValueDebounced || null },
    },
  })

  if (error) {
    return <GraphqlError error={error} />
  }

  if (loading && !data && !previousData) {
    return <CenteredLoadingSpinner />
  }

  const bookmarks = (data ?? previousData)?.bookmarks.edges.map((edge) => edge.node) ?? []

  return (
    <div className="p-4">
      {loading && <DelayedLoadingSpinner className="absolute right-8 top-8" />}

      <div className="mb-6">
        <h1 className="text-2xl font-semibold">All Bookmarks</h1>
        <div className="mt-4">
          <Form {...form}>
            <form className="space-y-4">
              <FormField
                control={form.control}
                name="search"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input type="search" placeholder="Search bookmarks..." {...field} />
                    </FormControl>
                  </FormItem>
                )}
              />
            </form>
          </Form>
        </div>
      </div>

      {bookmarks.length ? (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>ID</TableHead>
              <TableHead>URL</TableHead>
              <TableHead>Title</TableHead>
              <TableHead>User</TableHead>
              <TableHead>Groups</TableHead>
              <TableHead>Tags</TableHead>
              <TableHead>Description</TableHead>
              <TableHead>Created At</TableHead>
              <TableHead>Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {bookmarks.map((bookmark) => (
              <TableRow key={bookmark.id}>
                <TableCell>
                  <a
                    href={bookmarkDetailPath({ bookmarkId: bookmark.id })}
                    className="text-blue-600 hover:underline"
                    target="_blank"
                  >
                    {bookmark.id}
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    href={bookmark.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:underline"
                  >
                    {bookmark.url}
                  </a>
                </TableCell>

                <TableCell>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>{bookmark.title}</TooltipTrigger>
                      <TooltipContent>{bookmark.title}</TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </TableCell>
                <TableCell>
                  <Link
                    to={adminUserDetailPath({ id: bookmark.user.id })}
                    className="text-blue-600 hover:underline"
                  >
                    {bookmark.user.fullName}
                  </Link>
                </TableCell>
                <TableCell>
                  {bookmark.groups?.length > 0 ? (
                    <Popover>
                      <PopoverTrigger>
                        {bookmark.groups.map((g) => g.name).join(", ")}
                      </PopoverTrigger>
                      <PopoverContent className="w-80">
                        <div className="space-y-2">
                          {bookmark.groups.map((group) => (
                            <div key={group.id}>
                              <Link
                                to={adminGroupDetailPath({ id: group.id })}
                                className="text-blue-600 hover:underline"
                              >
                                {group.name}
                              </Link>
                            </div>
                          ))}
                        </div>
                      </PopoverContent>
                    </Popover>
                  ) : (
                    "-"
                  )}
                </TableCell>
                <TableCell>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        {bookmark.enrichment.tags?.edges.map((edge) => edge.node.name).join(", ")}
                      </TooltipTrigger>
                      <TooltipContent>
                        {bookmark.enrichment.tags?.edges.map((edge) => edge.node.name).join(", ")}
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </TableCell>
                <TableCell>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>{bookmark.enrichment.description}</TooltipTrigger>
                      <TooltipContent>{bookmark.enrichment.description}</TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </TableCell>
                <TableCell>{new Date(bookmark.createdAt).toLocaleString()}</TableCell>
                <TableCell>
                  {bookmark.canDelete.value && (
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={() =>
                        setDeleteBookmarkDialog({
                          id: bookmark.id,
                          title: bookmark.title,
                        })
                      }
                    >
                      🗑️ Delete
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <p>No bookmarks found</p>
      )}

      {data?.bookmarks.pageInfo.hasNextPage && (
        <div className="mt-4 flex justify-center">
          <Button
            variant="outline"
            onClick={() => {
              fetchMore({
                variables: {
                  after: data.bookmarks.pageInfo.endCursor,
                },
              })
            }}
          >
            Load more
          </Button>
        </div>
      )}

      <AlertDialog open={!!deleteBookmarkDialog} onOpenChange={() => setDeleteBookmarkDialog(null)}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Delete bookmark</AlertDialogTitle>
            <AlertDialogDescription asChild>
              <div>
                Are you sure you want to delete this bookmark? This action cannot be undone.
                <div className="mt-2 rounded-md bg-gray-100 p-4">{deleteBookmarkDialog?.title}</div>
              </div>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={async () => {
                if (!deleteBookmarkDialog) return

                const { errors } = await deleteBookmark({
                  variables: {
                    input: {
                      bookmarkId: deleteBookmarkDialog.id,
                    },
                  },
                })

                if (!errors) {
                  toast({
                    title: "Success",
                    description: `Deleted bookmark "${deleteBookmarkDialog.title}"`,
                  })
                  setDeleteBookmarkDialog(null)
                }
              }}
            >
              Delete Bookmark
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  )
}
