import { useViewer } from "~/auth/use-viewer"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { gql } from "~/__generated__"
import { Section } from "~/ui/section"
import { useSafeMutation } from "~/common/use-safe-mutation"
import { useValidationErrors } from "~/common/use-validation-errors"
import { Button } from "~/ui/button"
import { Form } from "~/ui/form"
import { useToast } from "~/ui/use-toast"
import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { AvatarUpload } from "./avatar-upload"
import { profilePath } from "~/common/paths"
import { FloatingTextField } from "~/fields/floating-text-field"

const mutation = gql(/* GraphQL */ `
  mutation UpdateProfile($input: UserUpdateInput!) {
    userUpdate(input: $input) {
      user {
        id
        firstName
        lastName
      }
    }
  }
`)

export const ProfileEditScreen = () => {
  const { toast } = useToast()
  const navigate = useNavigate()

  const { viewer } = useViewer()

  const formSchema = z.object({
    firstName: z.string(),
    lastName: z.string(),
    email: z.string(),
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    values: {
      firstName: viewer.firstName ?? "",
      lastName: viewer.lastName ?? "",
      email: viewer.email ?? "",
    },
  })

  const [exec, mutationResult] = useSafeMutation(mutation)
  useValidationErrors(form.setError, mutationResult)

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const result = await exec({
      variables: {
        input: {
          id: viewer.id,
          userInput: {
            firstName: values.firstName,
            lastName: values.lastName,
          },
        },
      },
    })

    if (!result.errors) {
      toast({
        title: "Profile Updated",
        description: "Your profile has been successfully updated.",
      })
      navigate(profilePath({}))
    }
  }

  return (
    <div className="flex flex-1 flex-col">
      <div className="bottom-1 flex items-center justify-between border-b border-gray-300 py-4">
        <h1 className="text-2xl">My Profile</h1>
      </div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="mb-4 flex items-center space-x-8 py-8">
            <div className="flex-none">
              <AvatarUpload userId={viewer.id} />
            </div>
            <div className="flex-1">
              <Section variant="grayBackground" className="space-y-4 p-0">
                <FloatingTextField
                  control={form.control}
                  name="firstName"
                  label="First Name"
                  required
                />
                <FloatingTextField
                  control={form.control}
                  name="lastName"
                  label="Last Name"
                  required
                />
              </Section>
            </div>
            <div className="flex-none">
              <div className="flex flex-col gap-4">
                <Button type="submit" disabled={form.formState.isSubmitting}>
                  Save
                </Button>
                <Link to={profilePath({})} className="text-center text-sm text-neutral-400">
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        </form>
      </Form>
    </div>
  )
}
