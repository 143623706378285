import { useQuery } from "@apollo/client"
import React from "react"
import { Link, useSearchParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import { useViewer } from "~/auth/use-viewer"
import { groupDetailPath } from "~/common/paths"
import { useSafeMutation } from "~/common/use-safe-mutation"
import { Button } from "~/ui/button"
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "~/ui/dialog"
import { GraphqlError } from "~/ui/errors"
import { LoadingSpinner } from "~/ui/loading-spinner"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "~/ui/table"
import { useToast } from "~/ui/use-toast"
import { useCopyShareLink } from "./use-copy-share-link"

import { LEAVE_GROUP_MUTATION } from "./group-mutations"

const GROUPS_QUERY = gql(/* GraphQL */ `
  query GroupsQuery($after: String) {
    groups(first: 50, after: $after, filters: { preset: MEMBER }) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          description
          name
          membersCount
          bookmarksCount
          shareToken
        }
      }
    }
  }
`)

import { GroupForm } from "./group-form"
import plusSquareIcon from "~/images/plus-square-icon.svg"

export const GroupIndexScreen = () => {
  const { data, loading, error, fetchMore } = useQuery(GROUPS_QUERY)
  const { toast } = useToast()
  const { viewer } = useViewer()
  const copyShareLink = useCopyShareLink()
  const [searchParams, setSearchParams] = useSearchParams()
  const [dialogOpen, setDialogOpen] = React.useState(searchParams.has("new"))
  const [leaveGroupDialog, setLeaveGroupDialog] = React.useState<{
    id: string
    name: string
  } | null>(null)

  const [leaveGroup] = useSafeMutation(LEAVE_GROUP_MUTATION, {
    refetchQueries: ["GroupsQuery"],
    onError: (error) => {
      const isLastAdmin = (error.graphQLErrors as any)?.[0]?.extensions?.details?.group?.includes(
        "last_admin"
      )

      toast({
        title: "Error",
        description: isLastAdmin
          ? "You are the last admin in this group. You need to promote another user to be admin or remove all members before you can leave."
          : error.message,
        variant: "destructive",
      })
    },
  })

  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return (
      <div className="container py-6">
        <GraphqlError error={error} />
      </div>
    )
  }

  invariant(data)

  return (
    <div className="container py-6">
      <div className="mb-6 flex items-center justify-between">
        <h1 className="text-2xl font-medium">My Groups</h1>
        <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
          <DialogTrigger asChild>
            <button className="flex">
              <img src={plusSquareIcon} className="self-center" width={18} height={18} />
              <span className="pl-2">Add Group</span>
            </button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Create New Group</DialogTitle>
            </DialogHeader>
            <GroupForm
              onSuccess={() => {
                setSearchParams({}, { replace: true })
                setDialogOpen(false)
              }}
              onClose={() => setDialogOpen(false)}
            />
          </DialogContent>
        </Dialog>
      </div>
      <Table className="table-auto">
        <TableHeader>
          <TableRow className="pointer-events-none">
            <TableHead className="text-xs text-gray-500">Groups</TableHead>
            <TableHead className="text-center text-xs text-gray-500">Members</TableHead>
            <TableHead className="text-center text-xs text-gray-500">Bookmarks</TableHead>
            <TableHead className="text-center text-xs text-gray-500">Share</TableHead>
            <TableHead></TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {data?.groups.edges
            .map((g) => g.node)
            .map((group) => (
              <TableRow key={group.id} className="border-y-2 border-white">
                <TableCell className="min-w-[500px] rounded-l-lg bg-gray-100 font-medium">
                  <Link to={groupDetailPath({ id: group.id })} title={group.description || ""}>
                    {group.name}
                  </Link>
                </TableCell>
                <TableCell className="min-w-[50px] bg-gray-100 text-center">
                  {group.membersCount}
                </TableCell>
                <TableCell className="min-w-[50px] bg-gray-100 text-center">
                  {group.bookmarksCount}
                </TableCell>
                <TableCell className="min-w-[50px] space-x-2 bg-gray-100 text-center">
                  <Button
                    variant="ghost"
                    onClick={() => copyShareLink(group.shareToken, viewer.id)}
                  >
                    🔗 Copy link
                  </Button>
                </TableCell>
                <TableCell className="font-regular min-w-[50px] rounded-r-lg bg-gray-100 text-center text-gray-400">
                  <Button
                    variant="ghost"
                    onClick={() => setLeaveGroupDialog({ id: group.id, name: group.name })}
                  >
                    Leave
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {data?.groups.pageInfo.hasNextPage && (
        <div className="mt-4 flex justify-center">
          <Button
            variant="outline"
            onClick={() => {
              fetchMore({
                variables: {
                  after: data.groups.pageInfo.endCursor,
                },
              })
            }}
          >
            Load more
          </Button>
        </div>
      )}
      <Dialog open={!!leaveGroupDialog} onOpenChange={() => setLeaveGroupDialog(null)}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Are you sure you want to leave group:</DialogTitle>
          </DialogHeader>
          <div className="rounded-md bg-gray-100 p-4">{leaveGroupDialog?.name}</div>
          <div className="flex flex-col gap-2">
            <Button
              variant="default"
              className="w-full"
              onClick={async () => {
                if (!leaveGroupDialog) return

                const { errors } = await leaveGroup({
                  variables: {
                    input: {
                      groupId: leaveGroupDialog.id,
                    },
                  },
                })

                if (!errors) {
                  toast({
                    title: "Success",
                    description: `Left group "${leaveGroupDialog.name}"`,
                  })
                  setLeaveGroupDialog(null)
                }
              }}
            >
              Leave Group
            </Button>
            <DialogClose asChild>
              <Button variant="ghost" className="w-full">
                Close &amp; Cancel
              </Button>
            </DialogClose>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}
