import { useQuery } from "@apollo/client"
import React, { useState } from "react"
import { useSearchParams } from "react-router-dom"
import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/use-safe-mutation"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "~/ui/alert-dialog"
import { Button } from "~/ui/button"
import { CenteredLoadingSpinner, DelayedLoadingSpinner } from "~/ui/delayed-loading-spinner"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "~/ui/dialog"
import { GraphqlError } from "~/ui/errors"
import { useToast } from "~/ui/use-toast"
import { ExclusionRuleForm } from "./exclusion-rule-form"

const EXCLUSION_RULE_DELETE_MUTATION = gql(/* GraphQL */ `
  mutation ExclusionRuleDelete($input: ExclusionRuleDeleteInput!) {
    exclusionRuleDelete(input: $input) {
      exclusionRule {
        id
      }
    }
  }
`)

const EXCLUSION_RULES_QUERY = gql(/* GraphQL */ `
  query ExclusionRules($after: String) {
    exclusionRules(first: 50, after: $after) {
      edges {
        node {
          id
          value
          createdAt
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`)

export const AdminExclusionRulesScreen: React.FC = () => {
  const { toast } = useToast()
  const [searchParams, setSearchParams] = useSearchParams()
  const [dialogOpen, setDialogOpen] = useState(searchParams.has("new"))
  const [deleteRuleId, setDeleteRuleId] = useState<string | null>(null)
  const [deleteExclusionRule] = useSafeMutation(EXCLUSION_RULE_DELETE_MUTATION)
  const { data, loading, error, refetch, fetchMore } = useQuery(EXCLUSION_RULES_QUERY, {
    variables: { after: null },
  })

  if (error) {
    return <GraphqlError error={error} />
  }

  if (loading && !data) {
    return <CenteredLoadingSpinner />
  }

  const rules = data?.exclusionRules.edges.map((edge) => edge.node) ?? []

  return (
    <div className="p-4">
      <div className="mb-6 flex items-center justify-between">
        <h1 className="text-2xl font-semibold">Exclusion Rules</h1>
        <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
          <DialogTrigger asChild>
            <Button>
              <span className="mr-2">+</span>
              Add Rule
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Create New Exclusion Rule</DialogTitle>
            </DialogHeader>
            <ExclusionRuleForm
              onSuccess={() => {
                setSearchParams({}, { replace: true })
                setDialogOpen(false)
                refetch()
              }}
              onClose={() => setDialogOpen(false)}
            />
          </DialogContent>
        </Dialog>
      </div>

      {loading && <DelayedLoadingSpinner />}

      <div className="rounded-md border">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Host</th>
              <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Created</th>
              <th className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                <span className="sr-only">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {rules.map((rule) => (
              <tr key={rule.id}>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{rule.value}</td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                  {new Date(rule.createdAt).toLocaleString()}
                </td>
                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                  <Button variant="ghost" size="sm" onClick={() => setDeleteRuleId(rule.id)}>
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {data?.exclusionRules.pageInfo.hasNextPage && (
        <div className="mt-4 flex justify-center">
          <Button
            variant="outline"
            onClick={() => {
              fetchMore({
                variables: {
                  after: data.exclusionRules.pageInfo.endCursor,
                },
              })
            }}
          >
            Load more
          </Button>
        </div>
      )}

      <AlertDialog open={!!deleteRuleId} onOpenChange={() => setDeleteRuleId(null)}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Delete Exclusion Rule</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to delete this exclusion rule? This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={async () => {
                if (!deleteRuleId) return

                const { errors } = await deleteExclusionRule({
                  variables: {
                    input: {
                      id: deleteRuleId,
                    },
                  },
                })

                if (!errors) {
                  toast({
                    title: "Success",
                    description: "Exclusion rule deleted successfully",
                  })
                  setDeleteRuleId(null)
                  refetch()
                }
              }}
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  )
}
