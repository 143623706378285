import React, { FC } from "react"
import { Tag } from "~/bookmarks/bookmark-index-screen"
import Pill from "./pill"

export interface FilteredTagListParams {
  filterTags: Array<Tag>
  toggleFilterTag: (tag: Tag) => void
}

const FilteredTagList: FC<FilteredTagListParams> = ({ filterTags, toggleFilterTag }) => {
  return (
    filterTags.length > 0 && (
      <div className="mt-6 flex justify-start space-x-2">
        <span className="self-center text-xs text-gray-400">Tags Applied:</span>
        {filterTags.map((filterTag) => (
          <Pill name={filterTag.name} onDelete={() => toggleFilterTag(filterTag)} />
        ))}
      </div>
    )
  )
}

export default FilteredTagList
